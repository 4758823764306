/**
 * Index component considered to be the root file of the app.
 */

import { Spin } from 'antd';
import Amplify from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import 'core-js/stable';
import { createBrowserHistory } from 'history';
import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import App from './components/App/App';
import PopupCheckerComponent from './components/common/PopupCheckerComponent';
// import { GOOGLE_ANALYTICS_TRACKING } from './config/config';
import configureStore from './configureStore';
import './fontawesomeIcons';
import './less/main.less';
import ProtectedRoute from './ProtectedRoute';
import * as serviceWorker from './serviceWorker';
import config from './AmplifyConfig';

const AppWithAuth = lazy(() => import('./components/App/AppWithAuth'));

Amplify.configure(config);

declare global {
    interface Window {
        less: any;
    }
}

interface IProps {}
// const usedGaId = get(
//     GOOGLE_ANALYTICS_TRACKING,
//     environment,
//     GOOGLE_ANALYTICS_TRACKING.dev
// );

const Root: React.FC<IProps> = () => {
    const history = createBrowserHistory();
    const initialState = window.initialReduxState;
    const store = configureStore(history, initialState);

    // const setupGoogleAnalytics = () => {
    //     const gaScript = document.createElement('script');
    //     gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${usedGaId}`;
    //     gaScript.async = true;
    //     document.body.appendChild(gaScript);
    //     gaScript.onload = () => {
    //         injectGA();
    //     };

    //     history.listen((location: { pathname: string }) => {
    //         if (typeof window == 'undefined') {
    //             return;
    //         }
    //         window.gtag('event', 'page_view', {
    //             page_location: `${window.location.origin}${location.pathname}`,
    //             page_path: location.pathname,
    //         });
    //     });
    // };

    // const injectGA = () => {
    //     if (typeof window == 'undefined') {
    //         return;
    //     }
    //     window.gtag('js', new Date());
    //     window.gtag('config', usedGaId);
    // };

    // useLayoutEffect(setupGoogleAnalytics, []);

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Suspense
                    fallback={
                        <Spin className="h-100 w-100 center-flex-all ta-center" />
                    }
                >
                    <Switch>
                        <Route path="/" exact component={AppWithAuth} />
                        <Route
                            path="/pop-up-checker"
                            exact
                            component={PopupCheckerComponent}
                        />
                        <ProtectedRoute path="/app" component={App} />
                        <Redirect to="/" />
                    </Switch>
                </Suspense>
            </ConnectedRouter>
        </Provider>
    );
};

render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
