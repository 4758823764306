import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { AuthActionTypes } from './types';

export const loginUserAction = (data: DynamicObject) =>
    action(AuthActionTypes.LOGIN_USER, data);
export const logoutUserAction = () => action(AuthActionTypes.LOGOUT_USER);
export const resetReduxStateAction = () =>
    action(AuthActionTypes.RESET_REDUX_STATE);

/**
 * Region config management
 */
export const getRegionSettingsConfigAction = () =>
    action(AuthActionTypes.GET_REGION_SETTINGS_CONFIG);

export const updateRegionSettingsConfigAction = (config: DynamicObject[]) =>
    action(AuthActionTypes.UPDATE_REGION_SETTINGS_CONFIG, config);

export const getRegionKeyConfigAction = () =>
    action(AuthActionTypes.GET_REGION_KEY_CONFIG);

export const updateRegionKeyConfigAction = (config: DynamicObject[]) =>
    action(AuthActionTypes.UPDATE_REGION_KEY_CONFIG, config);
