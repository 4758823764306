/**
 * Component responsible for handling the layout of the entire app.
 */

import { Layout, Spin } from 'antd';
import { debounce, isEmpty } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ROLE_PERMISSIONS from '../../frontend-permissions.json';
import AnnouncementsManagementPage from '../../pages/announcements/AnnouncementsManagementPage';
import SupportManagementPage from '../../pages/support/SupportManagementPage';
import GenerateRoutes from '../../routes/GenerateRoutes';
import {
    getAllRolesRequestAction,
    getRolePermissionsSuccessAction,
} from '../../store/roles/actions';
import { getRolePermissions } from '../../store/roles/sagas';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAuthHandler } from '../common/AuthHandler';
import PageHeader from './PageHeader';
import PageSidebar from './PageSidebar';

const { Content } = Layout;

let initialAllRolesFetchCalled = false;
interface IProps {
    location: {
        pathname: string;
    };
    logoutUser: () => void;
    openNotificationsMenu: (count?: number) => void;
}
const PageLayout: React.FC<IProps> = (props: IProps) => {
    const [windowHeight, setWindowHeight] = useState<number>(
        window.innerHeight
    );

    const isMounted = useRef<boolean>(false);
    const dispatch = useDispatch();

    const rolePermissions = useSelector(getRolePermissions);

    /**
     * PageSidebar routes / menu items.
     */
    const menuItems: DynamicObject[] = [
        // {
        //     name: 'Account Management',
        //     icon: ['fas', 'street-view'],
        //     route: '/account-management',
        //     component: AccountManagementPage,
        // },
        {
            name: 'Support',
            icon: ['fas', 'ambulance'],
            route: '',
            exact: true,
            component: SupportManagementPage,
        },
        {
            name: 'Announcements',
            icon: ['fas', 'bullhorn'],
            route: '/announcements',
            component: AnnouncementsManagementPage,
        },
    ];

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        setWindowHeight(window.innerHeight);
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', resizeWindowHandler);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, [dispatch]);

    /**
     * Function that fetches role permissions from a JSON file and sets it in redux.
     */
    const getRolePermissionsData = useCallback(() => {
        dispatch(getRolePermissionsSuccessAction(ROLE_PERMISSIONS));
    }, [dispatch]);

    //on Unmount
    useEffect(() => {
        return () => {
            initialAllRolesFetchCalled = false;
        };
    }, []);

    useEffect(() => {
        if (!initialAllRolesFetchCalled) {
            initialAllRolesFetchCalled = true;
            dispatch(getAllRolesRequestAction());
            getRolePermissionsData();
        }
    }, [dispatch, getRolePermissionsData]);

    const pageLayoutLoading = isEmpty(rolePermissions);

    return (
        <Spin
            spinning={pageLayoutLoading}
            tip="Loading company data"
            // size="large"
            style={{
                height: '80%',
            }}
        >
            <Layout
                className="h-100"
                style={{
                    maxHeight: windowHeight < 605 ? 605 : windowHeight,
                }}
            >
                <PageHeader
                    logoutUser={props.logoutUser}
                    openNotificationsMenu={props.openNotificationsMenu}
                    pageLayoutLoading={pageLayoutLoading}
                />
                <Layout>
                    {/* Sidebar Here */}
                    <PageSidebar menuItems={menuItems} />
                    <Layout className="page-layout-container">
                        <Content className="page-layout-content">
                            {/* Routes Section */}
                            {!isEmpty(rolePermissions) && (
                                <GenerateRoutes routeList={menuItems} />
                            )}
                            {/* end Routes Section */}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </Spin>
    );
};

export default withRouter(withAuthHandler(PageLayout));
