/**
 * Component for handling the Auth actions for user.
 */

import Auth from '@aws-amplify/auth';
import { Modal, notification } from 'antd';
import Cookies from 'js-cookie';
import { get, isFunction } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    IODM_COMPANY_STORAGE_NAME,
    initialPrimaryColor,
    TOKEN_NAME,
    TOKEN_NAME_EXPIRY,
} from '../../config/config';
import { UserJWTTokenAttribute } from '../../constants/authUserAttributes';
import {
    loginUserAction,
    logoutUserAction,
    resetReduxStateAction,
} from '../../store/auth/actions';
import {
    addDaysToDate,
    getBooleanIndicatorIfInactiveWithinPeriod,
    updateLessVariables,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';

export const withAuthHandler = (WrappedComponent: any) => {
    interface IProps {
        history: { push: (path: string) => void };
    }
    const AuthHandler: React.FC<IProps> = (props: IProps) => {
        const dispatch = useDispatch();

        /**
         * HOC function for logging in the user.
         * @param userData
         */
        const loginUser = (userData: DynamicObject) => {
            const isInactive = getBooleanIndicatorIfInactiveWithinPeriod();
            if (isInactive) {
                logoutUser();
            } else {
                Cookies.set(TOKEN_NAME, get(userData, UserJWTTokenAttribute), {
                    path: '/',
                    expires: addDaysToDate(new Date(), TOKEN_NAME_EXPIRY),
                });
                dispatch(
                    loginUserAction({
                        currentUser: userData,
                        isAuth: true,
                    })
                );
            }
        };

        /**
         * HOC function for logging out the user.
         * @param callback
         */
        const logoutUser = (callback?: () => void) => {
            Auth.signOut().then(() => {
                Cookies.remove(TOKEN_NAME, { path: '/' });
                localStorage.removeItem(IODM_COMPANY_STORAGE_NAME);
                dispatch(logoutUserAction());
                // Clear redux data on logout
                // dispatch(clearUsersStateDataAction());
                // // Reset last route so upon login, will go to the main route
                // dispatch(setCurrentRouteAction('/app'));
                dispatch(resetReduxStateAction());
                // Destroys all open modal
                Modal.destroyAll();
                // Destroys all notifications
                notification.destroy();
                updateLessVariables({
                    '@custom-primary-color': initialPrimaryColor,
                });
                if (callback && isFunction(callback)) callback();
                // if (props.history) {
                //     props.history.push('/');
                // }
            });
        };

        return (
            <WrappedComponent
                {...props}
                loginUser={loginUser}
                logoutUser={logoutUser}
            />
        );
    };

    return withRouter(AuthHandler);
};
