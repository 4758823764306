/**
 * Component for the Action bar section found in management pages.
 */

import { Button, Col, Icon, Modal, Row, Select } from 'antd';
import {
    capitalize,
    filter,
    get,
    includes,
    isEmpty,
    isObject,
    isUndefined,
    map,
} from 'lodash';
import React, {
    forwardRef,
    lazy,
    Ref,
    Suspense,
    useEffect,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../config/config';
// import { PAGE_NAMES_FOR_VIEW } from '../../config/tableAndPageConstants';
import { SubAttribute } from '../../constants/authUserAttributes';
import { ApplicationState } from '../../store';
import { getCurrentUser } from '../../store/auth/sagas';
import {
    deleteAppliedFiltersViewAction,
    fetchPageAppliedFiltersViewListRequestAction,
    updateRefetchPageViewsAction,
} from '../../store/common/actions';
import { PageView } from '../../store/common/types';
import { getRolePermissions, getUserRole } from '../../store/roles/sagas';
import { populatePopoverContainer } from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import FontAwesome from './FontAwesome';
import ProtectedButton from './ProtectedButton';
import ProtectedDrawerButton from './ProtectedDrawerButton';
import ProtectedPopoverButton from './ProtectedPopoverButton';

const ModalWithSpinner = lazy(() => import('./ModalWithSpinner'));

const { Option }: { Option: any } = Select;
const { confirm } = Modal;

interface IProps {
    readonly pageName?: string;
    readonly containerRef?: any;
    readonly displayFlex?: boolean;
    readonly actionItems: any[];
    readonly actionEllipsis?: any;
    readonly loading?: boolean;
    readonly ref?: Ref<any>;
    readonly filterBarOpen?: boolean;
}

let currentlySelectedView: string | undefined = undefined;
let unmounted = false;
const ActionBar: React.FC<IProps> = forwardRef(
    (
        {
            pageName,
            containerRef,
            displayFlex,
            actionItems,
            actionEllipsis,
            loading,
            filterBarOpen,
        }: IProps,
        ref: Ref<any>
    ) => {
        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this component.
         */
        React.useImperativeHandle(ref, () => ({
            getPageViewList: () => {
                return getPageViewList();
            },

            doesViewNameExist: (name: string) => {
                return doesViewNameExist(name);
            },
        }));

        const rolePermissions = useSelector(getRolePermissions);

        const currentUser = useSelector(getCurrentUser);
        const currentUserId: string = get(currentUser, SubAttribute);

        const dispatch = useDispatch();
        const willRefetchPageViews = useSelector(
            (state: ApplicationState) => state.app.refetchPageViews
        );
        const defaultPageViewValue = useSelector(
            (state: ApplicationState) => state.app.defaultPageViewValue
        );
        const userRole = useSelector(getUserRole);
        const [
            showDeleteViewLoading,
            setShowDeleteViewLoading,
        ] = useState<boolean>(false);

        const [pageViews, setPageViews] = useState<{
            list: DynamicObject[];
            loading: boolean;
        }>({
            list: [],
            loading: false,
        });

        const updatePageViewsObject = (pageViewsObject: DynamicObject) => {
            setPageViews({
                ...pageViews,
                ...pageViewsObject,
            });
        };

        const getPageViewList = () => {
            return pageViews.list;
        };

        const doesViewNameExist = (name: string) => {
            const filteredViewList = filter(pageViews.list, ['value', name]);
            return !isEmpty(filteredViewList);
        };

        const listenForPageNameAndRefetch = () => {
            if (unmounted) return;
            let fetchPageViews = willRefetchPageViews;

            if (pageName && fetchPageViews) {
                updatePageViewsObject({
                    list: [],
                    loading: true,
                });

                // if (isDashboard) {
                //     dispatch(getDashboardsRequestAction());
                // }

                dispatch(updateRefetchPageViewsAction(false));
                dispatch(
                    fetchPageAppliedFiltersViewListRequestAction(
                        pageName,
                        isDashboard,
                        (data: PageView[]) => {
                            let resetTableFilter = false;

                            if (unmounted) return;

                            const dropdownViewComponent = get(
                                filter(actionItems, [
                                    'actionType',
                                    'select-with-button',
                                ]),
                                0
                            );

                            const onSelectChange = get(
                                dropdownViewComponent,
                                'onSelectChange'
                            );

                            const currentTableFilter = get(
                                dropdownViewComponent,
                                'selectValue'
                            );

                            const selectedPageView = get(
                                filter(data, ['Name', currentTableFilter]),
                                0
                            );

                            if (
                                currentTableFilter &&
                                isEmpty(selectedPageView)
                            ) {
                                resetTableFilter = true;
                            }

                            const pageViewList = map(
                                data,
                                ({
                                    Id,
                                    Name,
                                    Category,
                                    CreatedUser,
                                    FilterState,
                                    DashboardState,
                                    IsDefaultView,
                                }: PageView) => {
                                    if (IsDefaultView) {
                                        if (onSelectChange) {
                                            if (
                                                resetTableFilter ||
                                                !filterBarOpen
                                            ) {
                                                onSelectChange(
                                                    Name,
                                                    resetTableFilter
                                                );
                                            } else if (
                                                !isUndefined(
                                                    defaultPageViewValue
                                                )
                                            ) {
                                                onSelectChange(
                                                    defaultPageViewValue
                                                );
                                            } else if (
                                                currentTableFilter &&
                                                !resetTableFilter
                                            ) {
                                                onSelectChange(
                                                    currentTableFilter,
                                                    false
                                                );
                                            }
                                        }
                                    } else if (isDashboard) {
                                        if (
                                            !isUndefined(
                                                defaultPageViewValue
                                            ) &&
                                            Name === defaultPageViewValue
                                        ) {
                                            onSelectChange(
                                                defaultPageViewValue
                                            );
                                        }
                                    }

                                    return {
                                        Id,
                                        label: Name,
                                        value: Name,
                                        Category,
                                        CreatedUser,
                                        FilterState,
                                        DashboardState,
                                        IsDefaultView,
                                    };
                                }
                            );

                            if (!isDashboard && isEmpty(pageViewList)) {
                                onSelectChange('');
                            }

                            setPageViews({
                                list: pageViewList,
                                loading: false,
                            });
                        }
                    )
                );
            }
        };

        useEffect(listenForPageNameAndRefetch, [
            pageName,
            willRefetchPageViews,
        ]);

        const setFetchPageViewsInitialLoad = () => {
            unmounted = false;
            if (pageName) dispatch(updateRefetchPageViewsAction(true));
            //will unmount
            return () => {
                unmounted = true;
                currentlySelectedView = undefined;
            };
        };

        useEffect(setFetchPageViewsInitialLoad, []);

        const isDashboard = false;
        const viewDashboardLabel = isDashboard ? 'dashboard' : 'view';

        /**
         * Function that confirms if you want to delete the selected option when clicking on x button
         * @param option
         */
        const deleteViewConfirmation = (option: any) => {
            return (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                e.preventDefault();
                e.stopPropagation();
                const { Id, value } = option;
                const defaultViewValue =
                    currentlySelectedView === value ? '' : undefined;

                confirm({
                    className: 'modal-swapped-buttons',
                    title: 'Do you want to continue?',
                    content: (
                        <div>
                            When you click the <b>{confirmModalOkText}</b>{' '}
                            button, the {`${viewDashboardLabel} `}
                            <b className="red">{get(option, 'label')}</b> will
                            be removed.
                        </div>
                    ),
                    onOk: () => deleteView(Id, defaultViewValue),
                    okText: confirmModalOkText,
                    cancelText: confirmModalCancelText,
                });
            };
        };

        /**
         * Function called when you confirmed to delete the view.
         * @param Id - Id of pageView to delete
         * @param defaultViewValue - default value of view dropdown after successfully deleting
         */
        const deleteView = (
            Id: string,
            defaultViewValue: string | undefined
        ) => {
            Modal.destroyAll();

            setShowDeleteViewLoading(true);
            dispatch(
                deleteAppliedFiltersViewAction(
                    {
                        Id,
                        PageName: pageName,
                    },
                    ({ IsSuccess, Messages }: ResponseModalObject) =>
                        deleteViewResponseModal(
                            IsSuccess,
                            Messages,
                            defaultViewValue
                        )
                )
            );
        };

        /**
         * Function for populating the response modal after deleting the view action API call has finished.
         * @param IsSuccess
         * @param Messages
         * @param defaultViewValue - default value of view dropdown after successfully deleting
         */
        const deleteViewResponseModal = (
            IsSuccess: boolean,
            Messages: string[] | undefined,
            defaultViewValue: string | undefined
        ) => {
            setShowDeleteViewLoading(false);

            if (IsSuccess) {
                Modal.success({
                    title: 'Success',
                    content: `${capitalize(
                        viewDashboardLabel
                    )} deleted successfully!`,
                    onOk: () => {
                        dispatch(
                            updateRefetchPageViewsAction(true, defaultViewValue)
                        );
                    },
                });
            } else {
                let errorMessageContent:
                    | string
                    | JSX.Element[] = `Failed to delete the ${viewDashboardLabel}!`;
                if (!isEmpty(Messages)) {
                    errorMessageContent = map(
                        Messages,
                        (error: string, index: number) => (
                            <div key={index}>{error}</div>
                        )
                    );
                }

                Modal.error({
                    title: 'Error',
                    content: errorMessageContent,
                });
            }
        };

        const populateViewIcon = (category: string) => {
            if (category === 'User') {
                return <FontAwesome icon={['fas', 'user']} />;
            } else if (category === 'Company') {
                return <FontAwesome icon={['fas', 'users']} />;
            } else {
                return <FontAwesome icon={['fas', 'cog']} />;
            }
        };

        /**
         * Function that populates dropdown section.
         * Usually the one on upper left next to refresh button.
         * @param options - list of options
         * @param hasDelete - boolean indicator if the dropdown option has delete functionality
         */
        const populateSelectOptions = (
            options: DynamicObject[],
            hasDelete?: boolean
        ) => {
            const allowedRolesCompany = rolePermissions.DASHBOARD_EDIT_COMPANY;
            const adminPowerRole =
                isEmpty(allowedRolesCompany) ||
                includes(allowedRolesCompany, userRole)
                    ? true
                    : false;

            const optionComponents = map(options, (option: any) => {
                let label, value;
                if (isObject(option)) {
                    label = get(option, 'label');
                    value = get(option, 'value');
                } else {
                    label = option;
                    value = option;
                }

                const canDelete =
                    hasDelete &&
                    get(option, 'Id') &&
                    get(option, 'Category') !== 'Default' &&
                    (get(option, 'CreatedUser.UserId') === currentUserId ||
                        adminPowerRole);
                return (
                    <Option key={value} value={value} label={label}>
                        <Row className={canDelete ? 'row-hover-delete' : ''}>
                            <Col span={canDelete ? 22 : 24} className="ws-nm">
                                <Row>
                                    <Col span={3} className="ta-center">
                                        {populateViewIcon(
                                            get(option, 'Category')
                                        )}
                                    </Col>
                                    <Col span={21}>{label}</Col>
                                </Row>
                            </Col>
                            {canDelete ? (
                                <Col span={2}>
                                    <Icon
                                        className="deletable dynamic-delete-button"
                                        type="close-circle"
                                        onClick={deleteViewConfirmation(option)}
                                    />
                                </Col>
                            ) : null}
                        </Row>
                    </Option>
                );
            });

            return optionComponents;
        };

        /**
         * Function for populating the select component (upper left table filter) and refresh button.
         * @param item - action bar item
         */
        const populateSelectWithButton = (item: DynamicObject) => {
            const { buttonContent, buttonDisabled, onButtonClick } = item;

            if (isDashboard) {
                item.selectOptions = [...pageViews.list];
            } else {
                if (!isEmpty(pageViews.list))
                    item.selectOptions = [...pageViews.list];
            }
            item.selectLoading = pageViews.loading;

            currentlySelectedView = item.selectValue;
            return (
                <>
                    {populateSelect(item, true)}
                    <Button
                        className="action-bar-button"
                        type="link"
                        disabled={buttonDisabled}
                        onClick={onButtonClick}
                    >
                        {buttonContent}
                    </Button>
                </>
            );
        };

        /**
         * Function that populates the dropdown components found in action bar.
         * @param item - action bar item
         * @param hasDelete - boolean indicator if the dropdown option has delete functionality
         */
        const populateSelect = (item: DynamicObject, hasDelete?: boolean) => {
            const {
                selectOptions,
                selectValue,
                selectDropdownRender,
                onSelectChange,
                minWidth,
                selectLoading,
                selectReadOnly,
            } = item;

            const loadingValue = loading || selectLoading;
            const readonlyProps = selectReadOnly ? { open: false } : null;
            return (
                <Select
                    loading={loadingValue}
                    disabled={loadingValue}
                    className={`action-bar-select ${
                        selectReadOnly ? 'cursor-na' : 'cursor-p'
                    }`}
                    value={selectValue}
                    onChange={onSelectChange}
                    optionLabelProp="label"
                    dropdownRender={selectDropdownRender}
                    getPopupContainer={populatePopoverContainer(containerRef)}
                    {...readonlyProps}
                    style={{ minWidth }}
                >
                    {populateSelectOptions(selectOptions, hasDelete)}
                </Select>
            );
        };

        /**
         * Function for populating a protected button.
         * This can be controlled (disabled / hidden).
         * Usually used using role restriction.
         * @param item - action bar item
         */
        const populateProtectedButton = (item: DynamicObject) => {
            const {
                allowedRoles,
                buttonDisabled,
                buttonLoading,
                onButtonClick,
                buttonContent,
                style,
                buttonType,
            } = item;
            return (
                <ProtectedButton
                    allowedRoles={allowedRoles}
                    buttonProps={{
                        className: 'action-bar-button',
                        type: buttonType || 'link',
                        onClick: onButtonClick,
                        disabled: buttonDisabled || loading,
                        loading: buttonLoading,
                        style: { ...style },
                    }}
                >
                    {buttonContent}
                </ProtectedButton>
            );
        };

        /**
         * Function for populating a protected button that triggers a popover upon clicking.
         * @param item - action bar item
         */
        const populateProtectedPopoverButton = (item: DynamicObject) => {
            const {
                allowedRoles,
                popoverVisible,
                popoverOnVisibleChange,
                popoverContent,
                buttonDisabled,
                buttonContent,
            } = item;
            return (
                <ProtectedPopoverButton
                    containerRef={containerRef}
                    allowedRoles={allowedRoles}
                    popoverVisible={popoverVisible}
                    popoverOnVisibleChange={popoverOnVisibleChange}
                    popoverPlacement="bottomLeft"
                    popoverContent={popoverContent}
                    popoverTrigger="click"
                    buttonType="link"
                    buttonClassName="action-bar-button"
                    buttonRestProps={{
                        disabled: buttonDisabled || loading,
                    }}
                    buttonContent={buttonContent}
                />
            );
        };

        /**
         * Function for populating a protected button that triggers a drawer upon clicking.
         * @param item - action bar item
         */
        const populateProtectedDrawerButton = (item: DynamicObject) => {
            const {
                drawerClosable,
                maskClosable,
                allowedRoles,
                popoverVisible,
                popoverOnVisibleChange,
                popoverTitle,
                popoverContent,
                buttonLoading,
                buttonDisabled,
                buttonContent,
                drawerWidth,
                onClose,
            } = item;
            return (
                <ProtectedDrawerButton
                    containerRef={containerRef}
                    drawerClosable={drawerClosable}
                    maskClosable={maskClosable}
                    allowedRoles={allowedRoles}
                    popoverVisible={popoverVisible}
                    popoverTitle={popoverTitle}
                    popoverContent={popoverContent}
                    buttonType="link"
                    buttonClassName="action-bar-button"
                    buttonRestProps={{
                        disabled: buttonDisabled || loading,
                        onClick: popoverOnVisibleChange,
                        loading: buttonLoading || false,
                    }}
                    buttonContent={buttonContent}
                    drawerWidth={drawerWidth}
                    onClose={onClose}
                />
            );
        };

        /**
         * Function for rendering each action bar item.
         */
        const renderActionItems = () => {
            const actionItemComponents = map(
                actionItems,
                (item: DynamicObject) => {
                    const { actionType, actionKey, allowedRoles } = item;
                    let itemContent;
                    if (
                        !isEmpty(allowedRoles) &&
                        !includes(allowedRoles, userRole)
                    ) {
                        return null;
                    }

                    if (actionType === 'select-with-button') {
                        itemContent = populateSelectWithButton(item);
                    } else if (actionType === 'select') {
                        itemContent = populateSelect(item);
                    } else if (actionType === 'protected-button') {
                        itemContent = populateProtectedButton(item);
                    } else if (actionType === 'protected-popover-button') {
                        itemContent = populateProtectedPopoverButton(item);
                    } else if (actionType === 'protected-drawer-button') {
                        itemContent = populateProtectedDrawerButton(item);
                    } else {
                        itemContent = item.display;
                    }

                    return <Col key={actionKey}>{itemContent}</Col>;
                }
            );

            return actionItemComponents;
        };

        /**
         * Function for rendering the `Ellipsis` section (Three vertical dots).
         */
        const renderEllipsisSection = () => {
            const {
                popoverVisible,
                popoverOnVisibleChange,
                popoverTitle,
                popoverContent,
                buttonContent,
            } = actionEllipsis;
            return (
                <ProtectedPopoverButton
                    containerRef={containerRef}
                    popoverVisible={popoverVisible}
                    popoverOnVisibleChange={popoverOnVisibleChange}
                    popoverPlacement="bottomRight"
                    popoverTitle={popoverTitle}
                    popoverContent={popoverContent}
                    popoverTrigger="click"
                    buttonType="link"
                    buttonClassName="pa-0"
                    buttonContent={buttonContent}
                />
            );
        };

        let rowContainerExtraProps = {};
        if (displayFlex) {
            rowContainerExtraProps = {
                type: 'flex',
                align: 'middle',
            };
        }

        return (
            <Row
                type="flex"
                justify="space-between"
                key="action-bar-container"
                {...rowContainerExtraProps}
            >
                <Col>
                    <Row type="flex">{renderActionItems()}</Row>
                </Col>
                {actionEllipsis && (
                    <Col span={1} className="ta-right">
                        {renderEllipsisSection()}
                    </Col>
                )}

                {showDeleteViewLoading && (
                    <Suspense fallback={null}>
                        <ModalWithSpinner
                            modalTitle={`Deleting ${viewDashboardLabel}`}
                            modalVisible={showDeleteViewLoading}
                            displayMessage={`Please wait while deleting the ${viewDashboardLabel}. . .`}
                            containerRef={containerRef}
                        />
                    </Suspense>
                )}
            </Row>
        );
    }
);

export default ActionBar;
