import API, { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import queries from '../../graphql/queries.graphql';
import { handleReduxErrorAction } from '../common/actions';
import { DynamicObject } from './../../utils/commonInterfaces';
import { getAllRolesSuccessAction } from './actions';
import { RolesActionTypes } from './types';

export const getAllRoles = (state: ApplicationState) => state.roles.allRoles;
export const getRolePermissions = (state: ApplicationState) =>
    state.roles.rolePermissions;

/**
 * Function for getting the currentUser
 */
export const getUserRole = (state: ApplicationState) =>
    get(state.auth.currentUser, 'Role.Name', '');

function* handleGetAllRolesRequest() {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_ALL_ROLES)
        );

        if (res.error) {
            console.log('res.error', res.error);
        } else {
            yield put(getAllRolesSuccessAction(res.data.GetAllRoles.Roles));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetAllRolesRequest() {
    yield takeLatest(
        RolesActionTypes.GET_ALL_ROLES_REQUEST,
        handleGetAllRolesRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* rolesSaga() {
    yield all([fork(watchGetAllRolesRequest)]);
}

export default rolesSaga;
