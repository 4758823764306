import Auth from '@aws-amplify/auth';
import { Modal, Skeleton } from 'antd';
import { print } from 'graphql/language/printer';
import { Parser } from 'html-to-react';
import invert from 'invert-color';
import Cookies from 'js-cookie';
import {
    clone,
    debounce,
    filter,
    forEach,
    get,
    has,
    includes,
    isEmpty,
    isEqual,
    last,
    map,
    omitBy,
    some,
    sum,
    times,
    toString,
    upperCase,
} from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { findDOMNode } from 'react-dom';
import config, {
    AMPLIFY_PUBLIC_CONFIG_DEV,
    AMPLIFY_PUBLIC_CONFIG_NONPROD,
    AMPLIFY_PUBLIC_CONFIG_PROD,
    AMPLIFY_PUBLIC_CONFIG_TEST,
    AMPLIFY_PUBLIC_CONFIG_UAT,
    Environments,
} from '../AmplifyConfig';
import {
    appliedFilterIndicator,
    customFieldIndicator,
    getMinMaxIndicator,
} from '../components/common/FilterBar';
import {
    ASSETS_LINK_DEV,
    ASSETS_LINK_NONPROD,
    ASSETS_LINK_PROD,
    ASSETS_LINK_TEST,
    ASSETS_LINK_UAT,
    COOKIE_LAST_ACTIVE_NAME,
    initialSecondaryColor,
    TIMEOUT_MILLISECOND,
    TIME_ACTIVE_COOKIE_SETTING,
    xlMin,
} from '../config/config';
import { SUPPORT_PAGE } from '../config/tableAndPageConstants';
import { dateSelectOptions, fileTypesSignature } from '../constants/common';
import {
    dateFormatFileDownload,
    dateFormatTimestamp,
    dateFormatYYYYMMDDDash,
    dateFormatYYYYMMDDTHHmmssDash,
} from '../constants/dateFormats';
import { hiddenCloudImportFields } from '../constants/settings';
import { CustomField } from '../store/common/types';
import { DynamicObject } from './commonInterfaces';

/**
 * Function that returns a hex color based on the string given.
 * Used for populating the Avatar icon color for name.
 * @param name - name of user
 */
export const stringToHex = (name: string) => {
    var hash = 0;
    if (name.length === 0) return hash;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    var color = '#';
    for (let x = 0; x < 3; x++) {
        var value = (hash >> (x * 8)) & 255;
        color += ('00' + value.toString(16)).substr(-2);
    }

    return color.toString();
};

/**
 * Function that returns the initial of a given name.
 * @param name - name of the user
 */
export const getNameInitials = (name: string) => {
    const parts = name.split(' ');
    let initials = '';
    const first = parts[0];
    const last = parts[parts.length - 1];
    initials += first.charAt(0).toUpperCase();
    initials += last.charAt(0).toUpperCase();
    return initials;
};

/**
 * Function that adds number of days to date.
 * @param date - date to add the number of days to
 * @param days - number of days to be added to date
 */
export const addDaysToDate = (date: Date, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

/**
 * Function that computes the table scroll x and y based on required value by Ant Design table.
 * @param tableHeightByWindow - table height estimate
 * @param pageSize - number of items in page
 * @param rowHeight - height of each table row
 * @param tableWidthByWindow - table width estimate
 */
export const computeTableScroll = (
    tableHeightByWindow: number,
    pageSize: number,
    rowHeight: number = SUPPORT_PAGE.rowHeight,
    tableWidthByWindow?: number
) => {
    const idealHeight = rowHeight * pageSize;
    let yValue;
    if (tableHeightByWindow <= idealHeight) {
        yValue = tableHeightByWindow;
    } else {
        yValue = pageSize * (rowHeight - 2);
    }

    const returnValue: any = {
        y: yValue,
    };

    if (tableWidthByWindow) {
        returnValue.x = tableWidthByWindow;
    }
    return returnValue;
};

/**
 * Function that refreshes the cognito session.
 * @param callback - function called after refreshing the session irregardless if there's
 * an error or not
 */
export const refreshCognitoAttributes = async (
    callback?: (err: any, session: any) => void
) => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();
    cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        if (callback) {
            callback(err, session);
        }
    });
};

/**
 * Function that updates the less variables in color.less file used for styling the UI
 * on the go.
 * @param colorVariables - color variables, must conform to the less variables defined
 * @param callback - callback function after variables have been updated
 */
export const updateLessVariables = (
    colorVariables: any,
    callback?: () => void
) => {
    const receivedColorVariables: any = { ...colorVariables };

    const primaryColor = receivedColorVariables['@custom-primary-color'];
    if (primaryColor) {
        receivedColorVariables['@contrast-custom-primary'] =
            getContrastColor(primaryColor);
    }
    receivedColorVariables['@custom-secondary-color'] = initialSecondaryColor; // overwrite secondary passed with the original grey one that we have
    const secondaryColor = receivedColorVariables['@custom-secondary-color'];

    if (secondaryColor) {
        receivedColorVariables['@contrast-custom-secondary'] =
            getContrastColor(secondaryColor);
    }

    window.less
        .modifyVars(receivedColorVariables)
        .then(() => {
            if (callback) {
                callback();
            }
        })
        .catch((error: any) => {
            console.log('failed to update theme', error);
        });
};

/**
 * Function that fetches the inverse of the color. Usually used for getting the font color
 * of a section with background.
 * @param color - color to get the inverse of
 */
export const getContrastColor = (color: any) => {
    const blackWhiteParams = {
        black: '#000000',
        white: '#ffffff',
        threshold: 0.4,
    };

    if (color) {
        return invert(color, blackWhiteParams);
    } else {
        return blackWhiteParams.black;
    }
};

/**
 * Function that gets the popover container based on the ref given.
 * If there's no ref given, or the given ref is invalid, should return the html body.
 * @param ref - ref for the component to be used as the wrapper
 */
export const getPopoverContainer = (ref: any) => {
    if (ref && ref.current) {
        return findDOMNode(ref.current) as HTMLElement;
    } else {
        return document.body;
    }
};

/**
 * Function that prevents the image from being cached by the browser by appending a timestamp to the path.
 * @param imagePath - current image path that is/will be cached by the browser
 */
export const invalidateImagePathCache = (imagePath: string) => {
    return imagePath + `?removeCachingTime=${new Date().getTime()}`;
};

/**
 * Function that returns the correct assets path based on the build environment.
 */
export const getAssetsPath = () => {
    let assetsPath = ASSETS_LINK_DEV;

    const buildEnvironment = getCurrentBuildEnvironment();
    if (buildEnvironment === Environments.DEV) {
        assetsPath = ASSETS_LINK_DEV;
    } else if (buildEnvironment === Environments.PROD) {
        assetsPath = ASSETS_LINK_PROD;
    } else if (buildEnvironment === Environments.TEST) {
        assetsPath = ASSETS_LINK_TEST;
    } else if (buildEnvironment === Environments.UAT) {
        assetsPath = ASSETS_LINK_UAT;
    } else if (buildEnvironment === Environments.NONPROD) {
        assetsPath = ASSETS_LINK_NONPROD;
    }

    return assetsPath;
};

/**
 * Function that returns the correct amplify config based on the build environment.
 */
export const getPublicAmplifyConfig = () => {
    let publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_DEV;
    const buildEnvironment = getCurrentBuildEnvironment();
    if (buildEnvironment === Environments.DEV) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_DEV;
    } else if (buildEnvironment === Environments.PROD) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_PROD;
    } else if (buildEnvironment === Environments.TEST) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_TEST;
    } else if (buildEnvironment === Environments.UAT) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_UAT;
    } else if (buildEnvironment === Environments.NONPROD) {
        publicAmplifyConfig = AMPLIFY_PUBLIC_CONFIG_NONPROD;
    }

    return publicAmplifyConfig;
};

/**
 * Function that check's if the view/device is for mobile/ipad view.
 */
export const getIsMobile = () => {
    return window.innerWidth < 758; //For size md
};

/**
 * Function that gets the current build environment.
 */
export const getCurrentBuildEnvironment = () => config.environment;

/**
 * Function that removes the indicator filters used for populating the filter bar section which the API doesn't really need.
 * @param filters - filters to be passed API as payload
 * @param processFilters - indicator if filter payload is to be processed or not
 * @param taskFilterProcessor - manages processor to be used for manipulating the filter payload is the one used in tasks page
 */
export const removeAppliedFiltersForApiRequest = (
    filters: {},
    processFilters: boolean = false
) => {
    const cleanFilterList: any = {};
    let filtersUsed = filters;
    const customFieldsFilters: DynamicObject[] = [];
    if (processFilters) {
        filtersUsed = populateFilterCalculationsForPayload(filters);
    }

    forEach(filtersUsed, (filter: any, keyName: string) => {
        if (includes(keyName, customFieldIndicator)) {
            if (includes(keyName, appliedFilterIndicator) && filter) {
                const usedKeyValue = keyName
                    .replace(customFieldIndicator, '')
                    .replace(appliedFilterIndicator, '');
                const customFieldKeyValArray = usedKeyValue.split('--');
                customFieldsFilters.push({
                    Type: get(customFieldKeyValArray, 0),
                    Name: get(customFieldKeyValArray, 1),
                    Value: filter,
                });
            }

            delete cleanFilterList[keyName];
        } else if (includes(keyName, getMinMaxIndicator)) {
            const dateFilterName = keyName.replace(getMinMaxIndicator, '');
            const { minDate, maxDate } = getDateFilterValues(
                get(filters, dateFilterName)
            );
            cleanFilterList[`${dateFilterName}Min`] = minDate;
            cleanFilterList[`${dateFilterName}Max`] = maxDate;
        } else if (
            !includes(keyName, appliedFilterIndicator) &&
            !(includes(keyName, 'Date') && get(filter, 'value')) // For date objects
        ) {
            cleanFilterList[keyName] = filter;
        }
    });

    if (!isEmpty(customFieldsFilters)) {
        cleanFilterList.CustomFieldFilters =
            JSON.stringify(customFieldsFilters);
    }

    return cleanFilterList;
};

/**
 * Function for processing the payload for invoices and customers filter payload
 * @param filters
 */
export const populateFilterCalculationsForPayload = (
    filters: DynamicObject
) => {
    const filterParams = { ...filters };
    const ContactData = filterParams.Contact;
    if (ContactData) {
        filterParams.Contact = sum(get(ContactData, 'Contact'));
        filterParams.InclusiveContact = get(ContactData, 'InclusiveContact');
    }
    const UserInputData = filterParams.UserInputData;
    if (UserInputData) {
        filterParams.User = get(UserInputData, 'Name');
        filterParams.FromCustomer = !isEmpty(
            get(UserInputData, 'FromCustomer')
        );

        delete filterParams.UserInputData;
    }

    const ResultData = filterParams.Result;
    if (ResultData) {
        filterParams.Result = sum(ResultData);
    }

    const Type = filterParams.Type;
    if (Type) {
        filterParams.Type = sum(Type);
    }

    return filterParams;
};

/**
 * Function that checks if the size of device used is Lg or below. Based on Ant design variables for Grid.
 */
export const getIfIsLg = () => {
    return window.innerWidth < xlMin;
};

/**
 * Function that populates loading skeletons.
 * @param iteration - number of loading skeletons to show
 * @param loading - loading indicator
 * @param title - title section included
 */
export const getLoadingSkeletons = (
    iteration: number,
    loading: boolean,
    title: boolean | DynamicObject = true,
    paragraph: boolean | DynamicObject = true
) => {
    const skeletons = times(iteration, (key: number) => (
        <Skeleton
            key={key}
            active
            loading={loading}
            title={title}
            paragraph={paragraph}
        />
    ));

    return <>{skeletons}</>;
};

/**
 * Function that inserts a specific array of elements at a given index.
 * @param arr - array to insert the items array
 * @param index - index where the array elements be inserted (starting)
 * @param itemArray - the array of items to be inserted
 */
export const insertAt = (arr: any[], index: number, itemArray: any[]) => [
    ...arr.slice(0, index),
    ...itemArray,
    ...arr.slice(index),
];

/**
 * Function that remove whitespaces from string.
 * @param str - string to remove the whitespaces from
 */
export const removeSpaces = (str: string) => {
    return str.replace(/\s/g, '');
};

/**
 * Function that calls a callback (empty the predefined filter) when
 * there are applied filter changes and dropdown filter state is undefined.
 * @param filters - filters object to compare to previously applied filter
 * @param tableFilters - filters object previously applied
 * @param dropdownStateValue - value of the dropdown state
 * @param fromFilterBar - boolean indicator if from FilterBar `Apply filters` button
 * @param callbackEmptyFilter - function to call when conditions are met
 */
export const emptyPredefinedFilterOnAppliedFilters = (
    filters: DynamicObject | undefined,
    tableFilters: DynamicObject | undefined,
    dropdownStateValue: any,
    fromFilterBar: boolean,
    callbackEmptyFilter: () => void
) => {
    const compactFilters = omitBy(filters, isEmpty);
    const compactTableFilters = omitBy(tableFilters, isEmpty);

    if (
        fromFilterBar &&
        !isEqual(compactFilters, compactTableFilters) &&
        !isEmpty(dropdownStateValue)
    ) {
        callbackEmptyFilter();
    }
};

/**
 * Common function that sets popover container based on the given containerRef.
 * @param containerRef - ref where any of the popover element will appear
 */
export const populatePopoverContainer = (containerRef?: any) => {
    return containerRef ? () => getPopoverContainer(containerRef) : undefined;
};

/**
 * Parser needed for rendering HTML strings to react instead of using `dangerouslySetInnerHTML`.
 */
const htmlParser = new Parser();
/**
 * Function that parses an html string to html object that can be rendered directly.
 * @param htmlString
 */
export const parseToHTML = (htmlString: string) => {
    return htmlParser.parse(htmlString);
};

/**
 * Common function for handling the selection of pageview option.
 * @param tableFilterValue - selected value of the dropdown
 * @param applyFiltersFunction - function to be called when applying the filters from `FilterState`
 * @param actionBarRefCurrent - ActionBar ref.current
 */
export const handlePageViewSelection = (
    tableFilterValue: string | undefined,
    applyFiltersFunction: Function,
    actionBarRefCurrent?: any
) => {
    if (actionBarRefCurrent) {
        const pageViewList = actionBarRefCurrent.getPageViewList();
        const selectedPageView = get(
            filter(pageViewList, ['value', tableFilterValue]),
            0
        );

        if (selectedPageView) {
            const filterState = get(selectedPageView, 'FilterState');
            const parsedFilterState = filterState
                ? JSON.parse(filterState)
                : null;

            const htmlFilterState: any = {};
            forEach(parsedFilterState, (stateValue, stateName) => {
                const filterHtml =
                    includes(stateName, appliedFilterIndicator) &&
                    /<\/?[a-z][\s\S]*>/.test(stateValue)
                        ? parseToHTML(stateValue)
                        : stateValue;

                htmlFilterState[stateName] = filterHtml;
            });

            if (htmlFilterState) applyFiltersFunction(htmlFilterState);
        }
    }
};

/**
 * Function that gets the date values (mostly min and max).
 * @param filterValue - value of filter
 */
export const getDateFilterValues = (filterValue: any) => {
    const filterSelected = get(filterValue, 'value');
    const {
        THIS_MONTH,
        NEXT_MONTH,
        LAST_MONTH,
        LAST_7_DAYS,
        LAST_30_DAYS,
        NEXT_7_DAYS,
        NEXT_30_DAYS,
        CUSTOM_DATE_RANGE,
        CUSTOM_DAYS_RANGE,
        NOW,
        TODAY,
        WITHIN_THIS_WEEK,
        WITHIN_NEXT_7_DAYS,
        LAST_WEEK_UP_TO_NOW,
        CREATE_DATE_ATB_CURRENT,
        CREATE_DATE_ATB_30_DAYS,
        CREATE_DATE_ATB_60_DAYS,
        CREATE_DATE_ATB_90_PLUS_DAYS,
        DUE_DATE_ATB_NOT_DUE,
        DUE_DATE_ATB_CURRENT,
        DUE_DATE_ATB_30_DAYS,
        DUE_DATE_ATB_60_DAYS,
        DUE_DATE_ATB_90_PLUS_DAYS,
        AVAILABLE_DATE_ATB_NOT_AVAILABLE,
        AVAILABLE_DATE_ATB_CURRENT,
        AVAILABLE_DATE_ATB_30_DAYS,
        AVAILABLE_DATE_ATB_60_DAYS,
        AVAILABLE_DATE_ATB_90_PLUS_DAYS,
    } = dateSelectOptions;
    let minDate: null | string | moment.Moment = '';
    let maxDate: null | string | moment.Moment = '';

    if (filterSelected === THIS_MONTH) {
        minDate = moment().startOf('month').format(dateFormatYYYYMMDDDash);
        maxDate = moment().endOf('month').format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === NEXT_MONTH) {
        const nextMonth = moment().add(1, 'month');
        minDate = moment(nextMonth)
            .startOf('month')
            .format(dateFormatYYYYMMDDDash);
        maxDate = moment(nextMonth)
            .endOf('month')
            .format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === LAST_MONTH) {
        const previousMonth = moment().subtract(1, 'month');
        minDate = moment(previousMonth)
            .startOf('month')
            .format(dateFormatYYYYMMDDDash);
        maxDate = moment(previousMonth)
            .endOf('month')
            .format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === LAST_7_DAYS) {
        const { startDate, endDate } = getDayRangeDates('Last', 7);
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === LAST_30_DAYS) {
        const { startDate, endDate } = getDayRangeDates('Last', 30);
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === NEXT_7_DAYS) {
        const { startDate, endDate } = getDayRangeDates('Next', 7);
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === NEXT_30_DAYS) {
        const { startDate, endDate } = getDayRangeDates('Next', 30);
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === CUSTOM_DATE_RANGE) {
        const startDate = moment(get(filterValue, 'From'));
        const endDate = moment(get(filterValue, 'To'));
        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === CUSTOM_DAYS_RANGE) {
        let startDate, endDate;
        if (get(filterValue, 'From') && get(filterValue, 'To')) {
            // if (filterNameRaw === 'ActionDate') {
            const fromValue = get(filterValue, 'From');

            const rangeTypeFrom = has(fromValue, 'Last') ? 'Last' : 'Next';
            const fromStart = getDayRangeDatesFromTo(
                rangeTypeFrom,
                fromValue[rangeTypeFrom]
            );

            const toValue = get(filterValue, 'To');
            const rangeTypeTo = has(toValue, 'Last') ? 'Last' : 'Next';
            const toEnd = getDayRangeDatesFromTo(
                rangeTypeTo,
                toValue[rangeTypeTo]
            );

            startDate = fromStart;
            endDate = toEnd;
        } else {
            const lastValue = get(filterValue, 'Last');
            const nextValue = get(filterValue, 'Next');
            if (lastValue !== undefined) {
                const { startDate: startVal, endDate: endVal } =
                    getDayRangeDates('Last', lastValue);
                startDate = startVal;
                endDate = endVal;
            } else if (nextValue !== undefined) {
                const { startDate: startVal, endDate: endVal } =
                    getDayRangeDates('Next', nextValue);
                startDate = startVal;
                endDate = endVal;
            }
        }

        if (startDate)
            minDate = moment(startDate).format(dateFormatYYYYMMDDDash);
        if (endDate) maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === NOW) {
        minDate = null;
        maxDate = moment().format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === TODAY) {
        minDate = moment().format(dateFormatYYYYMMDDDash);
        maxDate = moment().format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === WITHIN_THIS_WEEK) {
        minDate = moment().startOf('week').format(dateFormatYYYYMMDDDash);
        maxDate = moment().endOf('week').format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === WITHIN_NEXT_7_DAYS) {
        minDate = moment().format(dateFormatYYYYMMDDDash);
        const { endDate } = getDayRangeDates('Next', 7);
        maxDate = moment(endDate).format(dateFormatYYYYMMDDDash);
    } else if (filterSelected === LAST_WEEK_UP_TO_NOW) {
        const lastWeek = moment().subtract(1, 'week');
        minDate = lastWeek.startOf('week').format(dateFormatYYYYMMDDDash);
        maxDate = moment().format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_CURRENT ||
        filterSelected === DUE_DATE_ATB_CURRENT ||
        filterSelected === AVAILABLE_DATE_ATB_CURRENT
    ) {
        minDate = moment().subtract(29, 'days').format(dateFormatYYYYMMDDDash);
        maxDate = moment().format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_30_DAYS ||
        filterSelected === DUE_DATE_ATB_30_DAYS ||
        filterSelected === AVAILABLE_DATE_ATB_30_DAYS
    ) {
        minDate = moment().subtract(59, 'days').format(dateFormatYYYYMMDDDash);
        maxDate = moment().subtract(30, 'days').format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_60_DAYS ||
        filterSelected === DUE_DATE_ATB_60_DAYS ||
        filterSelected === AVAILABLE_DATE_ATB_60_DAYS
    ) {
        minDate = moment().subtract(89, 'days').format(dateFormatYYYYMMDDDash);
        maxDate = moment().subtract(60, 'days').format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === CREATE_DATE_ATB_90_PLUS_DAYS ||
        filterSelected === DUE_DATE_ATB_90_PLUS_DAYS ||
        filterSelected === AVAILABLE_DATE_ATB_90_PLUS_DAYS
    ) {
        minDate = null;
        maxDate = moment().subtract(90, 'days').format(dateFormatYYYYMMDDDash);
    } else if (
        filterSelected === DUE_DATE_ATB_NOT_DUE ||
        filterSelected === AVAILABLE_DATE_ATB_NOT_AVAILABLE
    ) {
        minDate = moment().add(1, 'day').format(dateFormatYYYYMMDDDash);
        maxDate = null;
    }
    const minDateWithTimeLocal = minDate ? minDate + 'T00:00:00' : null;
    const maxDateWithTimeLocal = maxDate ? maxDate + 'T23:59:59' : null;
    if (formatDateToDateObjectUTC) {
        if (minDateWithTimeLocal) {
            minDate = formatDateToDateObjectUTC(
                minDateWithTimeLocal,
                dateFormatYYYYMMDDTHHmmssDash
            );
        }

        if (maxDateWithTimeLocal) {
            maxDate = formatDateToDateObjectUTC(
                maxDateWithTimeLocal,
                dateFormatYYYYMMDDTHHmmssDash
            );
        }
    }

    return {
        minDate,
        maxDate,
    };
};

/**
 * Function that gets the dates based on range given and number of days
 * returns both start and end dates.
 * @param rangeType - Next, Last
 * @param days - number of days to add/subtract based on rangeType
 */
export const getDayRangeDates = (rangeType: 'Next' | 'Last', days: number) => {
    let startDate, endDate;
    if (rangeType === 'Next') {
        startDate = moment().add(1, 'day');
        endDate = moment(startDate).add(days - 1, 'days');
    } else if (rangeType === 'Last') {
        endDate = moment();
        startDate = moment(endDate).subtract(days, 'days');
    }

    return {
        startDate,
        endDate,
    };
};

/**
 * Function that gets the date based on rangeType and number of days
 * returns a single date (moment object).
 * @param rangeType - Next, Last
 * @param days - number of days to add/subtract based on rangeType
 */
const getDayRangeDatesFromTo = (rangeType: 'Next' | 'Last', days: number) => {
    if (rangeType === 'Next') {
        return moment(moment().add(1, 'day')).add(days - 1, 'days');
    } else if (rangeType === 'Last') {
        return moment().subtract(days, 'days');
    }
};

/**
 * Function that formats date (assumed local) to UTC date object.
 * Returns moment.Moment.
 * @param date - date in local date format
 * @param fromFormat - format of the date passed
 */
export const formatDateToDateObjectUTC = (
    date: any,
    fromFormat?: string | null
) => {
    const usedFromFormat = fromFormat || dateFormatYYYYMMDDTHHmmssDash;
    const localDatetime = moment(date, usedFromFormat).toDate();
    return moment.utc(localDatetime);
};

/*
 * Function to set the last active cookie value to current timestamp.
 */
export const setCookieLastActiveCurrent = () => {
    const currentTimeStamp = moment().format(dateFormatTimestamp);
    Cookies.set(COOKIE_LAST_ACTIVE_NAME, currentTimeStamp, {
        path: '/',
    });
};

/**
 * Function that gets the last active time stored as cookie with the ms allowance.
 */
const getCookieLastActiveTimeWithAllowance = () => {
    const cookieLastActive = Cookies.get(COOKIE_LAST_ACTIVE_NAME);
    const allowanceTimeout = TIMEOUT_MILLISECOND - TIME_ACTIVE_COOKIE_SETTING;
    const cookieLastActiveWithAllowance = moment(
        cookieLastActive,
        dateFormatTimestamp
    )
        .add(allowanceTimeout, 'ms')
        .format(dateFormatTimestamp);

    return cookieLastActiveWithAllowance;
};

/**
 * Function for getting a boolean if the user has been inactive within a period of time based on stored cookie.
 */
export const getBooleanIndicatorIfInactiveWithinPeriod = () => {
    const currentTimeStamp = moment().format(dateFormatTimestamp);
    const cookieLastActiveWithAllowance =
        getCookieLastActiveTimeWithAllowance();

    return moment(
        cookieLastActiveWithAllowance,
        dateFormatTimestamp
    ).isSameOrBefore(moment(currentTimeStamp, dateFormatTimestamp));
};

/**
 * Function that gets the number of milliseconds between the stored cookie active time and current timestamp.
 */
export const getDiffInMsLastActiveAndCurrentTimestamp = () => {
    const currentTimeStamp = moment().format(dateFormatTimestamp);
    const cookieLastActiveWithAllowance =
        getCookieLastActiveTimeWithAllowance();

    return moment(cookieLastActiveWithAllowance, dateFormatTimestamp).diff(
        moment(currentTimeStamp, dateFormatTimestamp),
        'ms'
    );
};

/**
 * Download to excel response handler (showing of modal response).
 */
export const downloadToExcelModalResponseHandler = (
    IsSuccess: boolean,
    Messages: string[] | undefined,
    callback?: () => void
) => {
    if (IsSuccess) {
        Modal.success({
            title: 'Success',
            content: `Data extraction process has started. You will be notified once it is finished together with the download link!`,
            onOk: () => {
                if (callback) callback();
            },
        });
    } else {
        let errorMessageContent: any = `Failed to start the data extraction process. Please try again later.`;
        if (!isEmpty(Messages)) {
            errorMessageContent = map(
                Messages,
                (error: string, index: number) => <div key={index}>{error}</div>
            );
        }
        Modal.error({
            title: 'Error',
            content: errorMessageContent,
        });
    }
};

/**
 * Function for populating the title section of change role modal.
 */
export const populateDownloadToExcelModalTitle = () => `Download as Excel`;

/**
 * Function for populating the body section of change role modal.
 */
export const populateDownloadToExcelModalDisplayMessage = () =>
    `Please wait while we start the data extraction process. . .`;

/**
 * Common function that downloads a file from the given presigned url.
 * @param presignedUrl
 * @param callback
 */
export const downloadFileFromPresignedUrl = (
    presignedUrl: string,
    callback?: () => void
) => {
    let fileName = '';

    fetch(presignedUrl)
        .then((response) => {
            const stringIndicator = '?X-Amz-Expires';
            const responseUrlArr = filter(
                response.url.split('/'),
                (stringElement: string) =>
                    includes(stringElement, stringIndicator)
            );

            const lastElement = responseUrlArr ? last(responseUrlArr) : '';
            if (lastElement) {
                fileName = get(
                    lastElement.match(`^(.+?)${stringIndicator}`),
                    1,
                    ''
                ).replace('?', '');
            }

            return response.blob();
        })
        .then((blob) => {
            if (callback) callback();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName.replace(
                '.xlsx',
                `${moment().format(dateFormatFileDownload)}.xlsx`
            );
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        });
};

/**
 * Function for getting the days overdue string/label based on the number of days provided.
 * @param date - date in utc
 * @param days - number of days
 */
export const populateDaysOverdue = (date: string, days: number | null) => {
    let overdueString = '';
    // if (days === 0) {
    //     const today = moment.utc();
    //     if (moment.utc(date).isSame(today, 'day')) {
    //         overdueString = 'Due today';
    //     } else {
    //         overdueString = 'Due tomorrow';
    //     }
    // } else if (days === 1) {
    //     overdueString = 'Due yesterday';
    // } else if (days === -1) {
    //     const tomorrow = moment.utc().add(1, 'day');

    //     if (moment.utc(date).isSame(tomorrow, 'day')) {
    //         overdueString = 'Due tomorrow';
    //     } else {
    //         overdueString = '-2 days overdue';
    //     }
    // } else {
    //     overdueString = `${days} days overdue`;
    // }
    if (days === 0) {
        overdueString = 'Due today';
    } else if (days === 1) {
        overdueString = 'Due yesterday';
    } else if (days === -1) {
        overdueString = 'Due tomorrow';
    } else {
        // if (!isNull(days) && days > 0) days++;
        overdueString = `${days} days overdue`;
    }

    return overdueString;
};

/**
 * Function that returns the sort field object based on the value selected
 * @param sortFieldValue - sort field value
 */
export const getSortFieldsWithCustomFields = (sortFieldValue: string) => {
    const sortObject: DynamicObject = {};
    if (includes(sortFieldValue, customFieldIndicator)) {
        const sortFieldValueArray = sortFieldValue
            .replace(customFieldIndicator, '')
            .split('--');
        sortObject.CustomFieldSort = JSON.stringify({
            Type: get(sortFieldValueArray, 0),
            Name: get(sortFieldValueArray, 1),
        });
    } else {
        sortObject.SortField = sortFieldValue;
    }

    return sortObject;
};

/**
 * Function that fetches the class names for each atb value.
 */
export const trialBalanceColorClassNames = (
    ATBData: any,
    isDueDateView: boolean
) => {
    /**
     * Function for getting the greatest amount label for aged trial balance.
     * @param mapValue
     */
    const getGreatestAmountLabel = (mapValue: any) => {
        let greatestAmount = 0;
        let forReturnLabel: any = '';

        forEach(mapValue, (amount, amountLabel) => {
            if (amount > 0) {
                if (amount === greatestAmount) {
                    forReturnLabel = `${forReturnLabel}+++${amountLabel}`;
                } else if (amount > greatestAmount) {
                    greatestAmount = amount;
                    forReturnLabel = amountLabel;
                }
            }
        });

        return forReturnLabel;
    };

    const greatestAmountLabel: [] =
        getGreatestAmountLabel(ATBData).split('+++');

    const overdueLabels = ['ThirtyDays', 'SixtyDays', 'NinetyPlusDays'];

    if (isDueDateView) {
        overdueLabels.push('Current');
    }

    let greatestHasOverdue = false;
    forEach(overdueLabels, (overdueLabel: string) => {
        if (includes(greatestAmountLabel, overdueLabel)) {
            greatestHasOverdue = true;
            return false;
        }
    });

    /**
     * Function that gets the classname/color for the aged trial balance label.
     * @param trialBalanceLabel
     */
    const getTrialBalanceColorClassname = (
        trialBalanceLabel: typeof ATBValues[number]
    ) => {
        if (
            includes(greatestAmountLabel, trialBalanceLabel) &&
            greatestHasOverdue &&
            includes(overdueLabels, trialBalanceLabel)
        ) {
            return 'red ws-nw';
        } else if (
            includes(greatestAmountLabel, trialBalanceLabel) &&
            !greatestHasOverdue &&
            !includes(overdueLabels, trialBalanceLabel)
        ) {
            return 'green ws-nw';
        } else {
            return 'ws-nw';
        }
    };

    const ATBValues = [
        'Current',
        'ThirtyDays',
        'SixtyDays',
        'NinetyPlusDays',
        'NotDue',
    ];

    const ATBClassNames: DynamicObject = {};
    forEach(ATBValues, (atbName: string) => {
        ATBClassNames[atbName] = getTrialBalanceColorClassname(atbName);
    });

    return ATBClassNames;
};

/**
 * Function that handles opening of URLs in new tab & focuses it, and pop-up blocker detection
 * @param url
 * @param callback
 */
export const openURLNewTab = (
    url: string,
    containerRef?: any,
    callback?: Function
) => {
    const newWin = window.open(url, '_blank');

    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        Modal.warning({
            title: 'Pop-up blocker enabled',
            content:
                'Please turn off your pop-up blocker for this site and refresh this page to proceed.',
            getContainer: () => getPopoverContainer(containerRef),
        });
    } else {
        newWin.focus();
        if (callback) callback();
    }
};
/**
 * Merge 2 array of objects by key.
 * @param a1 - Original array (contains all)
 * @param a2 - Array of objects that will override the data on the original array
 * @param key - Key of object to compare
 */
export const mergeByKey = (
    a1: DynamicObject[],
    a2: DynamicObject[],
    key: string
) =>
    a1.map((itm) => ({
        ...itm,
        ...a2.find((item) => item[key] === itm[key] && item),
    }));

export const debounceEventHandler = (
    eventFunction: (...args: any) => any,
    delayInMs: number
) => {
    const debounced = debounce(eventFunction, delayInMs);

    return function (e?: any) {
        if (e) e.persist();
        return debounced(e);
    };
};

export const customFieldsTableColumnValues = (
    customFieldType: string,
    customFieldList: CustomField[],
    customFieldsDataIndex: string[]
) => {
    const customFieldsTableObject: DynamicObject = {};

    forEach(customFieldList, ({ Name, Value }: CustomField) => {
        const customFieldDataIndexName = `${customFieldIndicator}${customFieldType}--${Name}`;

        const isNameInIndexCaseInsensitive = some(
            customFieldsDataIndex,
            (invoiceDataIdxName: string) => {
                return invoiceDataIdxName === customFieldDataIndexName;
            }
        );

        if (isNameInIndexCaseInsensitive) {
            customFieldsTableObject[customFieldDataIndexName] = Value;
        }
    });

    return customFieldsTableObject;
};

/**
 * Function that checks filetype by magic number
 */
export const checkFileTypeByMagicNumber = (
    file: any,
    allowedFileTypes: string[],
    callback: (response?: boolean) => void
) => {
    const fileName = get(file, 'name');
    // Bypass txt file as file signatures for them are very random.
    if (fileName && fileName.split('.').pop() === 'txt') {
        return callback(includes(allowedFileTypes, `.txt`));
    }

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = function (e) {
        const result = get(e, 'target.result');
        if (result) {
            const arr = new Uint8Array(result).subarray(0, 4);
            let fileSignature = '';
            for (let i = 0; i < arr.length; i++) {
                fileSignature += arr[i].toString(16);
            }
            const upperFileSignature = upperCase(fileSignature).replace(
                /\s/g,
                ''
            );

            let isValid = false;
            forEach(fileTypesSignature, (magicNumArray, fileType) => {
                const magicNumList = magicNumArray.join(',');
                if (includes(magicNumList, upperFileSignature)) {
                    if (includes(allowedFileTypes, `.${fileType}`)) {
                        isValid = true;
                        return false;
                    }
                }
            });

            callback(isValid);
        }
        // Check the file signature against known types
        //https://en.wikipedia.org/wiki/List_of_file_signatures
    };
};

/**
 * Function that gets the region config based on the list of data provided.
 */
export const getRegionConfigFromList = (
    companyRegion: string,
    keyData: DynamicObject[],
    settingsData: DynamicObject[]
) => {
    const keyConfig = get(filter(keyData, ['Region', companyRegion]), 0, {});
    const settingConfig = get(
        filter(settingsData, ['Region', companyRegion]),
        0,
        {}
    );
    return { ...keyConfig, ...settingConfig };
};

/**
 * Function that checks if the request should refetch based on error response
 */
export const checkShouldRequestRefetch = (err: any) =>
    includes(toString(err), 'Network Error') ||
    includes(get(err, 'errors.0.message'), 'Network Error') ||
    includes(get(err, 'response.data.Messages.0', ''), 'timed out') ||
    get(err, 'response.status') === 504;

/**
 * BYPASS CORS
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
export const postBypassCORS = (
    path: string,
    params: DynamicObject,
    method = 'post'
) => {
    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
};

/**
 * Function for getting sort by options if using cloud import type.
 */
export const getUsedSortByOptionsIfCloud = (
    usedSortByOptions: { label: string; value: string }[],
    isUsingCloudImportType: boolean
) => {
    return isUsingCloudImportType
        ? filter(
              usedSortByOptions,
              (option) => !includes(hiddenCloudImportFields, option.value)
          )
        : clone(usedSortByOptions);
};

export const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

/**
 * Function for getting query string from DocumentNode
 */
export const getGraphqlQueryString = (doc: DynamicObject) => {
    return print(doc);
};
