export const supportSortValues = {
    COMPANY_NAME: 'Name',
    COMPANY_GROUP: 'CompanyGroup',
    REGION: 'Region',
    CREATED_DATE: 'CreatedDateTime',
};

export const supportAmountOperatorOptions = [
    {
        label: 'Greater or matching',
        value: '>=',
    },
    {
        label: 'Greater than',
        value: '>',
    },
    {
        label: 'Lesser or matching',
        value: '<=',
    },
    {
        label: 'Less than',
        value: '<',
    },
];
export const supportAmountType = [
    { label: 'Owing', value: 'Owing' },
    { label: 'Total', value: 'Total' },
];
export const dateSelectOptions = {
    THIS_MONTH: 'This month',
    NEXT_MONTH: 'Next month',
    LAST_MONTH: 'Last month',
    LAST_7_DAYS: 'Last 7 days',
    LAST_30_DAYS: 'Last 30 days',
    NEXT_7_DAYS: 'Next 7 days',
    NEXT_30_DAYS: 'Next 30 days',
    CUSTOM_DATE_RANGE: 'Custom date range',
    CUSTOM_DAYS_RANGE: 'Custom days range',
    NOW: 'Now',
    WITHIN_THIS_WEEK: 'Within this week',
    WITHIN_NEXT_7_DAYS: 'Within next 7 days',
    LAST_WEEK_UP_TO_NOW: 'Last week up to now',
    TODAY: 'Today',
    CREATE_DATE_ATB_CURRENT: 'Create date ATB: Current',
    CREATE_DATE_ATB_30_DAYS: 'Create date ATB: 30 days',
    CREATE_DATE_ATB_60_DAYS: 'Create date ATB: 60 days',
    CREATE_DATE_ATB_90_PLUS_DAYS: 'Create date ATB: 90+ days',
    DUE_DATE_ATB_NOT_DUE: 'Due date ATB: Not due',
    DUE_DATE_ATB_CURRENT: 'Due date ATB: Current',
    DUE_DATE_ATB_30_DAYS: 'Due date ATB: 30 days',
    DUE_DATE_ATB_60_DAYS: 'Due date ATB: 60 days',
    DUE_DATE_ATB_90_PLUS_DAYS: 'Due date ATB: 90+ days',
    AVAILABLE_DATE_ATB_NOT_AVAILABLE: 'Available date ATB: Not available',
    AVAILABLE_DATE_ATB_CURRENT: 'Available date ATB: Current',
    AVAILABLE_DATE_ATB_30_DAYS: 'Available date ATB: 30 days',
    AVAILABLE_DATE_ATB_60_DAYS: 'Available date ATB: 60 days',
    AVAILABLE_DATE_ATB_90_PLUS_DAYS: 'Available date ATB: 90+ days',
};

const {
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
} = dateSelectOptions;

export const accessLogsDateOptions = [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const preConfiguredFilters = {
    ALL_COMPANIES: 'All companies',
};

export const supportTableFilterOptions = [
    // { label: 'All open support', value: 'Open' },
    { label: 'All companies', value: preConfiguredFilters.ALL_COMPANIES },
];

export const supportSortByOptions = [
    { label: 'Company name', value: supportSortValues.COMPANY_NAME },
    { label: 'Company group', value: supportSortValues.COMPANY_GROUP },
    { label: 'Region', value: supportSortValues.REGION },
    { label: 'Created date', value: supportSortValues.CREATED_DATE },
];
