/**
 * Root component for App if user is  signed in.
 * Contains the sessiont timeout component listener as well.
 */
import React from 'react';
import PageLayout from '../PageLayout/PageLayout';
import SessionTimeout from '../Session/SessionTimeout';

interface IProps {}
const App: React.FC<IProps> = (props: IProps) => {
    return (
        <div className="fixed-width" style={{ height: '100%' }}>
            <SessionTimeout />
            <PageLayout {...props} />
        </div>
    );
};

export default App;
