import { Col, Row } from 'antd';
import { get, isObject } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import {
    formFieldNames,
    initialValues,
} from '../../constants/announcementsSortAndFilters';
import { dateFormatYYYYMMDDTHHmmssDash } from '../../constants/dateFormats';
import { parseToHTML } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import AnnouncementTag from './AnnouncementTag';

interface IProps {
    formState: DynamicObject;
    formView?: boolean;
    reduxState?: DynamicObject;
    formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}
const EditAnnouncementPreview: React.FC<IProps> = ({
    formState,
    formView,
    reduxState,
    formatDateUTCToLocal,
}: IProps) => {
    const populateAnnouncementTag = () => {
        const formTagValue = get(formState, formFieldNames.TAG);
        const tagValue = formView
            ? formTagValue || initialValues.TAG
            : formTagValue;

        return tagValue && <AnnouncementTag tagValue={tagValue} />;
    };

    const populateDateTimeFromNow = () => {
        const formPublishDatetimeValue = get(
            formState,
            formFieldNames.PUBLISH_DATETIME
        );

        const selectedPublishDateTime = reduxState
            ? get(reduxState, formFieldNames.PUBLISH_DATETIME)
            : undefined;

        let publishDateTime = formView
            ? formPublishDatetimeValue || selectedPublishDateTime || moment()
            : formPublishDatetimeValue;

        if (!publishDateTime) return;

        if (formPublishDatetimeValue || selectedPublishDateTime) {
            const usedDateTime =
                formPublishDatetimeValue || selectedPublishDateTime;
            publishDateTime =
                usedDateTime && isObject(usedDateTime)
                    ? usedDateTime
                    : formatDateUTCToLocal(
                          usedDateTime,
                          null,
                          dateFormatYYYYMMDDTHHmmssDash
                      );
        }

        return (
            publishDateTime && (
                <div className="grey">{moment(publishDateTime).fromNow()}</div>
            )
        );
    };
    return (
        <div className="h-100">
            <Row>
                <Col>
                    <Row type="flex" align="middle" justify="space-between">
                        <Col>{populateAnnouncementTag()}</Col>
                        <Col>{populateDateTimeFromNow()}</Col>
                    </Row>
                </Col>
                <Col>
                    <div className="spacer-10" />
                    <Row>
                        <Col className="fs-16">
                            <b>{get(formState, formFieldNames.TITLE)}</b>
                        </Col>
                    </Row>
                    <div className="spacer-3" />
                    <Row>
                        <Col className="parsed-announcement-cont">
                            {parseToHTML(get(formState, formFieldNames.BODY))}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default withDateFormatHandler(EditAnnouncementPreview);
