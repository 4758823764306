export interface PageData {
    pageSize: number;
    currentPage: number;
    hasNextPage: boolean;
}

export enum CommonFiltersActionTypes {
    SAVE_APPLIED_FILTERS_VIEW = '@@common/filters/SAVE_APPLIED_FILTERS_VIEW',
    DELETE_APPLIED_FILTERS_VIEW = '@@common/filters/DELETE_APPLIED_FILTERS_VIEW',
    FETCH_PAGE_APPLIED_FILTERS_VIEW_LIST_REQUEST = '@@common/filters/FETCH_PAGE_APPLIED_FILTERS_VIEW_LIST_REQUEST',
    UPDATE_REFETCH_PAGE_VIEWS = '@@common/filters/UPDATE_REFETCH_PAGE_VIEWS',

    DOWNLOAD_REPORT_READY = '@@common/download/DOWNLOAD_REPORT_READY',

    GET_ATTACHMENT_PRESIGNED_URL = '@@common/attachments/GET_ATTACHMENT_PRESIGNED_URL',
    DELETE_ATTACHMENT = '@@common/attachments/DELETE_ATTACHMENT',
    UPDATE_ATTACHMENT_EDITING = '@@common/attachments/UPDATE_ATTACHMENT_EDITING',

    SET_LOCALE = '@@common/locale/SET_LOCALE',
    HANDLE_REDUX_ERROR = '@@common/error/HANDLE_REDUX_ERROR',
}

export interface PageView {
    Id: string;
    CreatedUser: string;
    Name: string;
    Category: string;
    FilterState: string;
    DashboardState: string;
    IsDefaultView: boolean;
}

export enum PageViewCategoryTypes {
    DEFAULT = 'Default',
    COMPANY = 'Company',
    USER = 'User',
}

export const PageViewCategoryTypeOptions = [
    { label: 'Personal', value: PageViewCategoryTypes.USER },
    { label: 'Company', value: PageViewCategoryTypes.COMPANY },
];

export const PageViewCategoryTypeOptionsPersonal = [
    { label: 'Personal', value: PageViewCategoryTypes.USER },
];

export interface AppCommonState {
    refetchPageViews: boolean;
    defaultPageViewValue: string | undefined;
    attachmentEditing: boolean;
    locale: string;
    currencyCode: string;
}

export interface CustomField {
    Name: string;
    Value: string;
}

export interface Attachment {
    FileId: string;
    IsPublic: boolean;
    Title: string;
    IsExternal: boolean;
    ExternalUrl: string;
}
