/**
 * File responsible for the content when clicking `Request Access` in management pages.
 */

import { Button, Col, Form, Modal, Row } from 'antd';
import { isEmpty, map } from 'lodash';
import React, { lazy, Suspense, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { companyRemoveAccessRequestAction } from '../../store/support/actions';
import { getPopoverContainer } from '../../utils/commonFunctions';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly filterPayload?: {};
}

const SupportRemoveAccess: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    filterPayload,
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    /**
     * Function called when `No` button is clicked inside Remove Access panel.
     */
    const handleClosePanel = (refetchList: boolean) => {
        if (closePanel) closePanel(refetchList);
    };

    /**
     * Function called when 'Yes' button is clicked.
     */
    const handleRemoveAccess = () => {
        setSubmitLoading(true);

        dispatch(
            companyRemoveAccessRequestAction({
                ...filterPayload,
                callback: removeAccessResponseModal,
            })
        );
    };

    /**
     * Function responsible for showing the response modal after removing access.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const removeAccessResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Access removed successfully!',
                onOk: () => handleClosePanel(false),
                getContainer: () => getPopoverContainer(containerRef),
            });
        } else {
            let errorMessageContent: any = `Failed to remove access!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form
                className="support-inline-form"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                <Row>
                    <Col>
                        Are you sure you want to remove the access for this
                        company?
                    </Col>
                </Row>
            </Form>
        );
    };

    if (!visible) {
        return null;
    }

    return (
        <Row>
            <Col>
                <div className="mb-10">{populatePanelContent()}</div>
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleRemoveAccess}
                        >
                            Yes
                        </Button>
                        <Button onClick={() => handleClosePanel(false)}>
                            No
                        </Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Removing access"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while removing access for the company . . ."
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

export default withRouter(SupportRemoveAccess);
