/**
 * Component for the Sales > Supports page. `/`
 */

import { Button, Col, Row, Select, Typography } from 'antd';
import {
    difference,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from '../../components/common/ActionBar';
import FontAwesome from '../../components/common/FontAwesome';
import VirtualizedList from '../../components/common/VirtualizedList';
import SupportAuditAll from '../../components/support/SupportAuditAll';
import SupportGainAccess from '../../components/support/SupportGainAccess';
import SupportItemComponent from '../../components/support/SupportItemComponent';
import SupportRemoveAccess from '../../components/support/SupportRemoveAccess';
import {
    PAGE_NAMES_FOR_VIEW,
    SUPPORT_PAGE,
} from '../../config/tableAndPageConstants';
import { supportSortByOptions } from '../../constants/supportSortAndFilters';
import { ApplicationState } from '../../store';
import {
    clearCompanyDataAction,
    getCompaniesRequestAction,
    getRegionFilterOptionsRequestAction,
    setSelectedCompanyIdRequestAction,
    updateCompaniesFiltersAction,
    updateCompaniesSortByStateAction,
    updateCompaniesTableFilterAction,
} from '../../store/support/actions';
import { initialState } from '../../store/support/reducer';
import { getSelectedCompanyId } from '../../store/support/sagas';
import {
    GetCompaniesRequestPayload,
    SupportState,
} from '../../store/support/types';
import {
    computeTableScroll,
    emptyPredefinedFilterOnAppliedFilters,
    getUsedSortByOptionsIfCloud,
    handlePageViewSelection,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';

const SupportItemDetailsDrawerComponent = lazy(
    () => import('../../components/support/SupportItemDetailsDrawerComponent')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

export const keyIndexName = 'CompanyId';

interface IProps {
    readonly isUsingCloudImportType: boolean;
}
let resetTableScroll = false;
let isRefetching = false;
let fetchSupportsRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = SUPPORT_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.SUPPORT_PAGE;
let initialTableFilter: undefined | string = undefined;
const SupportManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
}: IProps) => {
    const dispatch = useDispatch();

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();
    const selectedId = useSelector(getSelectedCompanyId);

    // const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>(
    //     []
    // );

    const [filterItemList, setFilterItemList] = useState<DynamicObject[]>([]);

    const [supportDetailsDrawer, setSupportDetailsDrawer] = useState<{
        visible: boolean;
        activeTab: undefined | string;
    }>({
        visible: false,
        activeTab: undefined,
    });

    const supportState: SupportState = useSelector(
        (state: ApplicationState) => state.support
    );

    const [supportTableFilter, setSupportsTableFilter] = useState<
        string | undefined
    >(
        isEqual(supportState.filters, initialState.filters)
            ? initialState.tableFilter
            : supportState.tableFilter
    );

    const [actionBarItemsState, setActionBarItemsState] = useState<{
        gainAccessBtnDisabled: boolean;
        gainAccessBtnLoading: boolean;
        removeAccessBtnDisabled: boolean;
        removeAccessBtnLoading: boolean;
    }>({
        gainAccessBtnDisabled: true,
        gainAccessBtnLoading: false,
        removeAccessBtnDisabled: true,
        removeAccessBtnLoading: false,
    });

    const [tableFilters, setTableFilters] = useState<any>(supportState.filters);

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    /**
     * Function that checks if there are filters applied and saved in redux.
     * Returns a boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(supportState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        gainAccessDrawer: boolean;
        removeAccessDrawer: boolean;
        auditDrawer: boolean;
        allSelected: boolean;
        downloadToExcel: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        gainAccessDrawer: false,
        removeAccessDrawer: false,
        auditDrawer: false,
        allSelected: false,
        downloadToExcel: false,
    });

    const [tableSortState, setTableSortState] = useState<{
        sortBy: string;
        sortAscending: boolean;
    }>({
        sortBy: supportState.sortBy,
        sortAscending: supportState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(supportState, 'pageData.currentPage', 0)
    );

    /**
     * Function to get used sort options
     */
    const getSupportsSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(
            supportSortByOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        const usedSortByOptions = getSupportsSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        // forEach(
        //     customFieldsFilterList,
        //     ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
        //         usedSortValues.push(
        //             `${customFieldIndicator}${Type}--${FieldName}`
        //         );
        //     }
        // );
        if (!includes(usedSortValues, supportState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortState,
                    sortBy: initialState.sortBy,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updateCompaniesSortByStateAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [supportState.sortBy]);

    /**
     * Function for fetching the region filter options.
     */
    const getRegionFilterOptions = () => {
        dispatch(getRegionFilterOptionsRequestAction());
    };

    /**
     * Function for getting the filter values to be used in FilterBar component.
     */
    const populateSupportFilterBarFilters = () => {
        const regionFilterOptions: DynamicObject[] = map(
            supportState.regionFilterOptions,
            (region: string) => ({
                label: region,
                value: region,
            })
        );

        const filterBarFilters = [
            {
                filterName: 'Company Name',
                filterStateName: 'CompanyName',
                filterElement: 'input',
                filterType: 'text',
            },
            {
                filterName: 'Company Group',
                filterStateName: 'CompanyGroup',
                filterElement: 'input',
                filterType: 'text',
            },
            {
                filterName: 'Region',
                filterStateName: 'Regions',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: regionFilterOptions,
                filterLoading: supportState.regionFilterOptionsLoading,
                span: 10,
            },
        ];

        setFilterItemList(filterBarFilters);
    };

    useEffect(populateSupportFilterBarFilters, [
        supportState.regionFilterOptions,
    ]);

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetCompaniesRequestPayload = {
            filters: supportState.filters,
            sortBy: tableSortState.sortBy,
            sortAscending: tableSortState.sortAscending,
            pageSize,
            currentPage: currentPage,
        };

        return payload;
    };

    /**
     * Function that calls an action which triggers a saga for fetching the Companies data.
     * @param currentPage - current page to fetch data from
     * @param pageSize - number of items in page
     */
    const fetchCompanies = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;

        dispatch(getCompaniesRequestAction(payload));
    };

    /**
     * Function called in preparation for fetching the support data.
     * Manages the page to be called.
     */
    const handleFetch = () => {
        // if (isUndefined(initialTableFilter) || supportState.loading) return;
        if (supportState.loading) return;

        if (!supportState.pageData.hasNextPage) return;

        // const nextPage = tableCurrentPage + 1;
        // setTableCurrentPage(nextPage);
        // fetchCompanies(nextPage);
    };

    /**
     * Listener function for calling the fetch support function.
     * Can be triggered upon changes on selected user company, filter values, sort values, and support state filter update.
     */
    const callFetchSupports = () => {
        fetchCompanies(0);
    };

    const initializeRegionFilterOptions = () => {
        getRegionFilterOptions();
    };

    useEffect(initializeRegionFilterOptions, []);

    useEffect(callFetchSupports, [
        supportState.sortBy,
        supportState.sortAscending,
        supportState.filters,
    ]);

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = supportState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchSupportsRequired) {
                fetchCompanies(0);
                fetchSupportsRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [supportState.tableFilter]);

    // on Unmount
    useEffect(() => {
        return () => {
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    /**
     * Listener function responsible for showing the state of checkboxes (checked/unchecked) upon data update.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(supportState.data, keyIndexName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [supportState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function called when support table filter (upper-left dropdown next to refresh button) is updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeSupportsTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== supportState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setSupportsTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === supportState.tableFilter) {
            handleSupportFilterRefresh();
        } else {
            dispatch(updateCompaniesTableFilterAction(filter));
        }
    };

    /**
     * Function called when the refresh button is clicked.
     */
    const handleSupportFilterRefresh = () => {
        fetchSupportsRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchCompanies(0);
    };

    /**
     * Function for getting the selected support values.
     */
    // const getSelectedSupportsValues = () => {
    //     let allExcept = false;
    //     let keysToUse = [...tableRowSelection.selectedRowKeys];

    //     if (showConditions.allSelected) {
    //         allExcept = true;
    //         keysToUse = [...tableRowSelection.unselectedRowKeys];
    //     }
    //     const filterObject = {
    //         ...tableFilters,
    //         SupportState: tableSortState.supportState,
    //     };

    //     return {
    //         allExcept,
    //         keysToUse,
    //         filterObject,
    //     };
    // };

    /**
     * Function for populating the dropdown render of the table filter (upper-left).
     * @param menu - menu items component
     */
    const populateSupportFilterSelectDropdownRender = (menu: any) => (
        <div>{menu}</div>
    );

    /**
     * Function for populating the `Select/Deselect all` button label.
     */
    // const populateSelectDeselectAllLabel = () => {
    //     const allDataLoaded = supportState.pageData.hasNextPage === false;

    //     let selectDeselectLabel = '';
    //     if (
    //         !isEmpty(supportState.data) &&
    //         ((tableRowSelection.unselectedRowKeys.length === 0 &&
    //             showConditions.allSelected) ||
    //             (allDataLoaded &&
    //                 supportState.data.length ===
    //                     tableRowSelection.selectedRowKeys.length))
    //     ) {
    //         selectDeselectLabel = 'Deselect';
    //     } else {
    //         selectDeselectLabel = 'Select';
    //     }

    //     return `${selectDeselectLabel} all`;
    // };

    /**
     * Function triggered when the select/deselect all button is clicked.
     */
    // const selectAllRows = () => {
    //     const selectAllCondition =
    //         isEmpty(tableRowSelection.selectedRowKeys) ||
    //         tableRowSelection.selectedRowKeys.length < supportState.data.length;

    //     updateShowConditionsObject({
    //         allSelected: selectAllCondition,
    //     });

    //     if (selectAllCondition) {
    //         const idKeys: string[] = map(supportState.data, keyIndexName);

    //         updateTableRowSelection({
    //             selectedRowKeys: idKeys,
    //             unselectedRowKeys: [],
    //         });

    //         updateActionBarItemsState({
    //             raiseQueryButtonLoading: true,
    //         });
    //     } else {
    //         updateTableRowSelection({
    //             selectedRowKeys: [],
    //         });

    //         updateActionBarItemsState({
    //             raiseQueryButtonDisabled: true,
    //             raiseQueryButtonLoading: false,
    //         });
    //     }
    // };

    /**
     * Function called when the sort order button link is clicked (inside the ellipsis popover).
     * @param sortAscending - boolean indicator if sort order is ascending
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateCompaniesSortByStateAction({
                ...tableSortState,
                sortAscending,
            })
        );
    };

    const downloadDisabled = supportState.loading || isEmpty(supportState.data);

    /**
     * Function for populating the upper section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                Filter
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            supportState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                Change sort order
            </Button>
        </div>
    );

    /**
     * Function for populating the lower section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverContent = () => (
        <div className="pop-action-content">
            <div className="mb-10">
                <span>Sort by</span>
            </div>
            <div>
                <Select
                    onChange={(sortBySelected: string) =>
                        updateTableSortStateObject({
                            sortBy: sortBySelected,
                        })
                    }
                    value={tableSortState.sortBy}
                >
                    {map(
                        getSupportsSortByOptions(),
                        ({
                            label,
                            value,
                        }: {
                            label: string;
                            value: string;
                        }) => (
                            <Option key={value} value={value}>
                                {label}
                            </Option>
                        )
                    )}
                    {/* {map(
                         customFieldsFilterList,
                         ({
                             Type,
                             Number: CFNumber,
                             FieldName,
                         }: CompanyCustomFieldConfigure) => (
                             <Option
                                 key={Type + CFNumber + FieldName}
                                 value={`${customFieldIndicator}${Type}--${FieldName}`}
                             >
                                 {FieldName}
                             </Option>
                         )
                     )} */}
                </Select>
            </div>
            <br />
            <div className="ta-right">
                <Button
                    type="primary"
                    disabled={
                        supportState.loading ||
                        tableSortState.sortBy === supportState.sortBy
                    }
                    onClick={applySortedByAndState}
                >
                    Apply
                </Button>
            </div>
        </div>
    );

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (supportState.tableFilter !== initialTableFilter) {
            setSupportsTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeSupportsTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called when applying the filters.
     * Set the filters in state and redux.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetCompaniesRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    supportTableFilter,
                    fromFilterBar,
                    () => {
                        changeSupportsTableFilter('');
                    }
                );
            }
        }

        const appliedFilters = filters || initialState.filters;

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateCompaniesFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys();
    };

    const applySortedByAndState = async () => {
        await resetTableScrollAndPageData();
        await dispatch(updateCompaniesSortByStateAction(tableSortState));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param showConditionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function for updating the `actionBarItemsState` state.
     * @param itemStateObject - must conform to `actionBarItemsState`
     */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState({
            ...actionBarItemsState,
            ...itemStateObject,
        });
    };

    /**
     * Function called to close the support details drawer.
     * Clears the data saved during drawer close.
     */
    const closeSupportDetailsDrawer = () => {
        setSupportDetailsDrawer({
            visible: false,
            activeTab: undefined,
        });
        dispatch(clearCompanyDataAction());
    };

    /**
     * Function to update the `tableSortState` state.
     * @param tableSortStateObject - must conform to `tableSortState`
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when clicking on checkbox.
     * @param record - record for the row where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     * @param activeTab - kind of panel to show based on the row clicked
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {
        dispatch(
            setSelectedCompanyIdRequestAction(get(record, keyIndexName), () => {
                setSupportDetailsDrawer({
                    visible: true,
                    activeTab,
                });
            })
        );
    };

    /**
     * Function for populating the table loading text.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } companies`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function called when the row is selected (left side checkbox is ticked).
     * @param record - data for the selected row
     * @param selected - boolean indicator if the row is selected/unselected
     * @param selectedRows - selected row keys
     * @param nativeEvent - event fired when the row is selected
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });
    };

    /**
     * Function to control the visibility of popovers.
     * @param name - name of popover to change the visibility
     * @param condition - boolean indicator to apply the condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (
                    name === 'gainAccessDrawer' ||
                    name === 'removeAccessDrawer' ||
                    name === 'auditDrawer'
                ) {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for resetting the table scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all the selected/unselected row keys.
     */
    const resetAllSelectedRowKeys = () => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        updateShowConditionsObject({
            allSelected: false,
        });

        updateActionBarItemsState({
            raiseQueryButtonDisabled: true,
        });
    };

    /**
     * Common function for getting the payload needed for Gain/Remove access.
     * @returns
     */
    const getAccessRelatedPayload = () => {
        const selectedKey = get(tableRowSelection, 'selectedRowKeys.0');

        return {
            CompanyId: selectedKey,
        };
    };

    /**
     * Function for closing the filter bar section.
     * Resets the applied filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>Support</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    // pageName={pageName}
                                    loading={supportState.loading}
                                    applyFilters={applyFilters}
                                    filters={filterItemList}
                                    filterValues={supportState.filters}
                                    colDivision={6}
                                    closeAllPopovers={
                                        supportDetailsDrawer.visible
                                    }
                                    closeFilterBar={closeFilterBar}
                                    appliedView={supportTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    // customFieldsFilters={customFieldsFilterList}
                                />
                            </Suspense>
                        </div>
                    )}
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={supportState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'support-filter',
                                actionType: 'select-with-button',
                                selectValue: supportTableFilter,
                                selectDropdownRender:
                                    populateSupportFilterSelectDropdownRender,
                                onSelectChange: changeSupportsTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>Refresh</span>
                                    </>
                                ),
                                buttonDisabled: supportState.loading,
                                onButtonClick: handleSupportFilterRefresh,
                                defaultValue: 'All',
                            },
                            {
                                actionKey: 'support-gain-access',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.gainAccessDrawer,
                                drawerClosable: true,
                                maskClosable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'gainAccessDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: 'Request access to account',
                                popoverContent: (
                                    <SupportGainAccess
                                        visible={
                                            showConditions.gainAccessDrawer
                                        }
                                        closePanel={popoverOnVisibleChange(
                                            'gainAccessDrawer',
                                            true
                                        )}
                                        filterPayload={getAccessRelatedPayload()}
                                    />
                                ),
                                buttonDisabled:
                                    tableRowSelection.selectedRowKeys.length !==
                                    1,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'sign-in-alt']}
                                        />
                                        <span>Gain Access</span>
                                    </>
                                ),
                                drawerWidth: 500,
                                onClose: popoverOnVisibleChange(
                                    'gainAccessDrawer',
                                    true
                                ),
                            },
                            {
                                actionKey: 'support-remove-access',
                                actionType: 'protected-drawer-button',
                                popoverVisible:
                                    showConditions.removeAccessDrawer,
                                drawerClosable: true,
                                maskClosable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'removeAccessDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: 'Remove access to account',
                                popoverContent: (
                                    <SupportRemoveAccess
                                        visible={
                                            showConditions.removeAccessDrawer
                                        }
                                        closePanel={popoverOnVisibleChange(
                                            'removeAccessDrawer',
                                            true
                                        )}
                                        filterPayload={getAccessRelatedPayload()}
                                    />
                                ),
                                buttonDisabled:
                                    tableRowSelection.selectedRowKeys.length !==
                                    1,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'sign-out-alt']}
                                        />
                                        <span>Remove Access</span>
                                    </>
                                ),
                                drawerWidth: 500,
                                onClose: popoverOnVisibleChange(
                                    'removeAccessDrawer',
                                    true
                                ),
                            },
                            {
                                actionKey: 'support-audit',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.auditDrawer,
                                drawerClosable: true,
                                maskClosable: false,
                                popoverOnVisibleChange:
                                    popoverOnVisibleChange('auditDrawer'),
                                popoverTitle:
                                    'Retrieve access logs for companies',
                                popoverContent: (
                                    <div>
                                        <SupportAuditAll
                                            visible={showConditions.auditDrawer}
                                            closePanel={popoverOnVisibleChange(
                                                'auditDrawer',
                                                true
                                            )}
                                            // filterPayload={getAccessRelatedPayload()}
                                        />
                                    </div>
                                ),
                                buttonDisabled: supportState.loading,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'clipboard-list']}
                                        />
                                        <span>Audit</span>
                                    </>
                                ),
                                drawerWidth: 500,
                                onClose: popoverOnVisibleChange(
                                    'auditDrawer',
                                    true
                                ),
                            },
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={supportState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                SUPPORT_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={supportState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(supportState.errorMessages)
                                    ? get(supportState, 'errorMessages.0')
                                    : 'No companies found'
                            }
                            hasNextPage={supportState.pageData.hasNextPage}
                            itemComponent={SupportItemComponent}
                            itemHeight={SUPPORT_PAGE.rowHeight}
                            selectedId={selectedId}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <SupportItemDetailsDrawerComponent
                            visible={supportDetailsDrawer.visible}
                            activeTab={supportDetailsDrawer.activeTab}
                            closeDrawer={closeSupportDetailsDrawer}
                        />
                    </Suspense>
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={populateDownloadToExcelModalTitle()}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={populateDownloadToExcelModalDisplayMessage()}
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default SupportManagementPage;
