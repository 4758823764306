/**
 * Component for the page header. The one at the top that contains the Company logo, company dropdown and user dropdown.
 */

import {
    Avatar,
    Button,
    Col,
    Dropdown,
    Icon,
    Layout,
    Menu,
    Row,
    Spin,
} from 'antd';
import { get } from 'lodash';
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    EmailAttribute,
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
import { getCurrentUser } from '../../store/auth/sagas';
import {
    getAssetsPath,
    getNameInitials,
    stringToHex,
} from '../../utils/commonFunctions';
import FontAwesome from '../common/FontAwesome';

const { Header } = Layout;

const assetsPath = getAssetsPath();
const iodmLogo = `${assetsPath}/logo.png`;

const ImageWithLoading = lazy(() => import('../common/ImageWithLoading'));
interface IProps {
    logoutUser: () => void;
    openNotificationsMenu: (count?: number) => void;
    pageLayoutLoading: boolean;
    history: any;
}
const PageHeader: React.FC<IProps> = (props: IProps) => {
    const currentUser = useSelector(getCurrentUser);

    const username = get(currentUser, GivenNameAttribute);

    const fullName = `${get(currentUser, GivenNameAttribute)} ${get(
        currentUser,
        FamilyNameAttribute
    )}`;

    /**
     * Function to populate the user dropdown overlay.
     */
    const populateUserDropdownMenuOverlay = () => (
        <Menu>
            <Menu.Item>
                <div className="center-flex-sb">
                    <div>{populateUserAvatar('large')}</div>
                    <div className="pa-10 mw-300px">
                        <div className="ws-nm">{fullName}</div>
                        <div className="ws-nm">
                            {get(currentUser, EmailAttribute)}
                        </div>
                    </div>
                </div>
            </Menu.Item>
            <Menu.Divider />
            {/* <Menu.Item>
                <span className="center-flex">
                    <FontAwesome
                        className="mr-10 fs-18"
                        icon={['fas', 'address-card']}
                    />
                    My Profile
                </span>
            </Menu.Item> */}
            {/* <Menu.Item onClick={handleNotificationsClick}>
                <span className="center-flex">
                    <span className="notification-circle mr-10">
                        {notificationsCount}
                    </span>
                    Notifications
                </span>
            </Menu.Item> */}
            {/* <Menu.SubMenu
                className="submenu-flex"
                title={
                    <span className="center-flex">
                        <FontAwesome
                            className="mr-10 fs-20"
                            icon={['fas', 'question-circle']}
                        />{' '}
                        Support
                    </span>
                }
            >
                <Menu.Item onClick={goToOnlineHelp}>Online Help</Menu.Item>
                <Menu.Item>What's New</Menu.Item>
                <Menu.Item>Contact Support</Menu.Item>
            </Menu.SubMenu>
            <Menu.Divider /> */}
            <Menu.Item onClick={handleLogout}>
                <div className="mb-20">
                    <div className="center-flex">
                        <FontAwesome
                            className="mr-10 fs-20"
                            icon={['fas', 'sign-out-alt']}
                        />{' '}
                        Sign Out
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    );

    const usedAvatarColor: any = stringToHex(fullName);

    /**
     * Function to populate the user avatar icon.
     * @param size
     */
    const populateUserAvatar = (
        size: number | 'default' | 'small' | 'large' | undefined = 'default'
    ) => (
        <Avatar size={size} style={{ backgroundColor: usedAvatarColor }}>
            {getNameInitials(fullName)}
        </Avatar>
    );

    /**
     * Function called when user clicks on logout button in user popover.
     */
    const handleLogout = () => {
        props.logoutUser();
    };

    const elementsCursor = 'cursor-p';
    return (
        <Header className="header">
            <Row>
                <Col
                    xs={{ span: 6, offset: 0 }}
                    sm={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                >
                    <div className="logo">
                        <Suspense fallback={<Spin />}>
                            <ImageWithLoading
                                src={iodmLogo}
                                alt="IODM Logo"
                                style={{ width: '60px', height: 'auto' }}
                            />
                        </Suspense>
                    </div>
                </Col>
                <Col
                    xs={{ span: 12, offset: 0 }}
                    sm={{ span: 12, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    style={{ textAlign: 'center' }}
                >
                    <div className="secondary-contrast-color sup-portal">
                        Support Portal
                    </div>
                </Col>
                <Col
                    className="ta-right"
                    xs={{ span: 6, offset: 0 }}
                    sm={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                >
                    <span className={elementsCursor}>
                        <Dropdown
                            trigger={['click']}
                            overlay={populateUserDropdownMenuOverlay()}
                            // disabled={isEdittingDashboard}
                        >
                            <span>
                                <Button
                                    type="link"
                                    className="pa-0 mr-10 header-username secondary-contrast-color"
                                >
                                    {username} <Icon type="down" />
                                </Button>
                                {/* <Badge
                                    className={elementsCursor}
                                    count={notificationsCount}
                                    offset={[3, 6]}
                                > */}
                                {populateUserAvatar()}
                                {/* </Badge> */}
                            </span>
                        </Dropdown>
                    </span>
                </Col>
            </Row>
        </Header>
    );
};

export default withRouter(PageHeader);
