import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import {
    Company,
    CompanyAccessLog,
    GetCompaniesRequestPayload,
    GetCompanyAccessLogsRequestPayload,
    SupportActionTypes,
    WorkflowOption,
} from './types';

/**
 * Actions for getting the company list in Support page.
 */
export const getCompaniesRequestAction = (
    payload: GetCompaniesRequestPayload
) => action(SupportActionTypes.GET_COMPANIES_REQUEST, payload);
export const getCompaniesSuccessAction = (payload: {
    data: Company[];
    pageData: PageData;
}) => action(SupportActionTypes.GET_COMPANIES_SUCCESS, payload);
export const getCompaniesErrorAction = (payload: string[]) =>
    action(SupportActionTypes.GET_COMPANIES_ERROR, payload);

export const updateCompaniesFiltersAction = (
    filters: GetCompaniesRequestPayload['filters']
) => action(SupportActionTypes.UPDATE_COMPANIES_FILTERS, filters);
export const updateCompaniesSortByStateAction = (tableSortState: {
    sortBy: GetCompaniesRequestPayload['sortBy'];
    sortAscending: boolean;
}) => action(SupportActionTypes.UPDATE_COMPANIES_SORT_BY_STATE, tableSortState);

export const updateCompaniesTableFilterAction = (tableFilter: string) =>
    action(SupportActionTypes.UPDATE_COMPANIES_TABLE_FILTER_STATE, tableFilter);

// Single Record
export const setSelectedCompanyIdRequestAction = (
    companyId: string | null,
    callback: () => void
) =>
    action(SupportActionTypes.SET_COMPANY_SELECTED_ID_REQUEST, {
        companyId,
        callback,
    });
export const setSelectedCompanyIdSuccessAction = (companyId: string | null) =>
    action(SupportActionTypes.SET_COMPANY_SELECTED_ID_SUCCESS, companyId);

/**
 * Actions for getting Support page - company data.
 */
export const getCompanyDataRequestAction = (companyId: string) =>
    action(SupportActionTypes.GET_COMPANY_DATA_REQUEST, {
        companyId,
    });
export const getCompanyDataSuccessAction = (payload: { record: {} }) =>
    action(SupportActionTypes.GET_COMPANY_DATA_SUCCESS, payload);
export const getCompanyDataErrorAction = (payload: string[]) =>
    action(SupportActionTypes.GET_COMPANY_DATA_ERROR, payload);

export const clearCompanyDataAction = () =>
    action(SupportActionTypes.CLEAR_COMPANY_DATA_SUCCESS);

/**
 * Action for getting region filter options to be used for filter options.
 */
export const getRegionFilterOptionsRequestAction = () =>
    action(SupportActionTypes.GET_REGION_FILTER_OPTIONS_REQUEST);
export const getRegionFilterOptionsResponseAction = (payload: string[]) =>
    action(SupportActionTypes.GET_REGION_FILTER_OPTIONS_RESPONSE, payload);

/**
 * Action for requesting company access.
 */
export const companyGainAccessRequestAction = (payload: DynamicObject) =>
    action(SupportActionTypes.GAIN_COMPANY_ACCESS_REQUEST, payload);

/**
 * Action for removing company access.
 */
export const companyRemoveAccessRequestAction = (payload: DynamicObject) =>
    action(SupportActionTypes.REMOVE_COMPANY_ACCESS_REQUEST, payload);

/**
 * Action for getting audit for access logs - all companies.
 */
export const getCompaniesAccessLogsRequestAction = (payload: DynamicObject) =>
    action(SupportActionTypes.GET_COMPANIES_ACCESS_LOGS_REQUEST, payload);

/*
 * Actions for getting a single company access logs.
 */
export const getCompanyAccessLogsRequestAction = (
    payload: GetCompanyAccessLogsRequestPayload
) => action(SupportActionTypes.GET_COMPANY_ACCESS_LOGS_REQUEST, payload);
export const getCompanyAccessLogsSuccessAction = (payload: {
    data: CompanyAccessLog[];
    pageData: PageData;
}) => action(SupportActionTypes.GET_COMPANY_ACCESS_LOGS_SUCCESS, payload);
export const getCompanyAccessLogsErrorAction = (payload: string[]) =>
    action(SupportActionTypes.GET_COMPANY_ACCESS_LOGS_ERROR, payload);

export const updateCompanyAccessLogsFiltersAction = (
    filters: GetCompanyAccessLogsRequestPayload['filters']
) => action(SupportActionTypes.UPDATE_COMPANY_ACCESS_LOGS_FILTERS, filters);

/**
 * Actions responsible for getting the workflow names for the company.
 */
export const getCompanyWorkflowOptionsRequestAction = (
    companyId: string,
    callback: (workflowOptions: WorkflowOption[]) => void
) =>
    action(SupportActionTypes.GET_COMPANY_WORKFLOW_OPTIONS_REQUEST, {
        companyId,
        callback,
    });

/**
 * Action for getting the workflow step options based on workflow selected.
 * @param payload
 */
export const getWorkflowStepOptionsRequestAction = (
    companyId: string,
    workflowId: string,
    callback: (response: any[]) => void
) =>
    action(SupportActionTypes.GET_WORKFLOW_STEP_OPTIONS_REQUEST, {
        companyId,
        workflowId,
        callback,
    });

/**
 * Action for getting the workflow state information
 * @param payload
 */
export const getWorkflowStateForCompanyRequestAction = (
    companyId: string,
    workflowId: string,
    workflowStateName: string,
    callback: (response: DynamicObject) => void
) =>
    action(SupportActionTypes.GET_WORKFLOW_STATE_FOR_COMPANY_REQUEST, {
        companyId,
        workflowId,
        workflowStateName,
        callback,
    });
