import AutoComplete from 'antd/lib/auto-complete';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input/Input';
import { SelectValue } from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import { API } from 'aws-amplify';
import { filter, forEach, get, includes, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import queries from '../../graphql/queries.graphql';
import { populatePopoverContainer } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { customFieldIndicator } from './FilterBar';

export const FILTER_SEARCH_DEBOUNCE_TIME = 2000; //ms
let unmounted: boolean = false;
interface IProps {
    updateField: (value: string) => void;
    stateValue: string;
    queryName: string;
    filterField: string | undefined;
    sortField: string;
    responseName: string;
    labelField: string;
    updateFiltersFunction: () => void;
    onPressEnter: () => void;
}
let timeoutHandle: any = null;

const InputAutoCompleteWithButton: React.FC<IProps> = ({
    updateField,
    stateValue,
    queryName,
    filterField,
    sortField,
    responseName,
    labelField,
    updateFiltersFunction,
    onPressEnter,
}: IProps) => {
    const containerRef = useRef(null);
    const [inputState, setInputState] = useState<{
        fetching: boolean;
        data: [];
        value: string | undefined;
    }>({
        fetching: false,
        data: [],
        value: stateValue || undefined,
    });

    /**
     * Common function for updating the input state.
     * @param inputStateObject - must conform to inputState object
     */
    const updateInputState = (inputStateObject: {}) => {
        setInputState({
            ...inputState,
            ...inputStateObject,
        });
    };

    const checkStateValueUpdate = () => {
        if (!stateValue) {
            updateInputState({
                fetching: false,
                data: [],
                value: stateValue || undefined,
            });
        }
    };

    useEffect(checkStateValueUpdate, [stateValue]);

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmounted = false;

        //will unmount
        return () => {
            unmounted = true;
        };
    };

    useEffect(setInitialLoad, []);

    const fetchOptions = (value: string) => {
        updateField(value);
        if (value.length >= 3) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            timeoutHandle = setTimeout(() => {
                updateInputState({ value, data: [], fetching: true });

                getDataOptions(value);
            }, FILTER_SEARCH_DEBOUNCE_TIME);
        } else {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            updateInputState({ value, data: [], fetching: false });
        }
    };

    const getDataOptions = (value: string) => {
        if (!filterField) return;
        const queryVariables: DynamicObject = {
            PageSize: 10,
            Skip: 0,
            Ascending: true,
        };
        let usedCustomFieldName = '';
        if (includes(filterField, customFieldIndicator)) {
            const usedKeyValue = filterField.replace(customFieldIndicator, '');
            const customFieldKeyValArray = usedKeyValue.split('--');
            usedCustomFieldName = get(customFieldKeyValArray, 1);
            queryVariables.CustomFieldFilters = JSON.stringify([
                {
                    Type: get(customFieldKeyValArray, 0),
                    Name: usedCustomFieldName,
                    Value: value,
                },
            ]);
            queryVariables.CustomFieldSort = sortField;
        } else {
            queryVariables[filterField] = value;
            queryVariables.SortField = sortField;
        }

        API.graphql({
            query: queries[queryName],
            variables: queryVariables,
        })
            .then((res: any) => {
                if (unmounted) return;
                const data: string[] = [];
                forEach(
                    get(res, `data.${responseName}`),
                    (opt: DynamicObject) => {
                        let optValue = '';

                        if (includes(filterField, customFieldIndicator)) {
                            const customFieldValues = filter(opt.CustomFields, [
                                'Name',
                                usedCustomFieldName,
                            ]);
                            optValue = get(customFieldValues, '0.Value');
                        } else {
                            optValue = opt[labelField];
                        }
                        if (!includes(data, optValue)) {
                            data.push(optValue);
                        }
                    }
                );

                updateInputState({ value, data, fetching: false });
            })
            .catch(() => {
                if (unmounted) return;
                updateInputState({ value, data: [], fetching: false });
            });
    };

    const handleChange = (value: SelectValue) => {
        updateInputState({
            value: value as string,
            data: [],
            fetching: false,
        });
    };

    const handleSelect = (value: SelectValue) => {
        updateField(value as string);
    };

    const onOkClick = () => {
        if (timeoutHandle) clearTimeout(timeoutHandle);
        updateInputState({
            fetching: false,
            data: [],
            value: value || undefined,
        });
        updateFiltersFunction();
    };

    const onInputPressEnter = () => {
        if (isEmpty(inputState.data)) {
            onPressEnter();
        }
    };
    const { fetching, data, value } = inputState;

    return (
        <div className="pop-action-content" ref={containerRef}>
            <div>
                <AutoComplete
                    className="autocomplete-input"
                    value={value}
                    dataSource={data}
                    notFoundContent={null}
                    filterOption={false}
                    onSearch={fetchOptions}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    dropdownClassName="autocomplete-input-dropdown"
                    getPopupContainer={populatePopoverContainer(containerRef)}
                >
                    <Input
                        prefix={
                            fetching ? (
                                <Spin
                                    indicator={
                                        <Icon
                                            type="loading"
                                            style={{ fontSize: 12 }}
                                            spin
                                        />
                                    }
                                />
                            ) : (
                                <Icon type="search" />
                            )
                        }
                        placeholder="Search"
                        allowClear
                        onPressEnter={onInputPressEnter}
                    />
                </AutoComplete>
            </div>
            <div className="ok-button-container">
                <Button type="primary" onClick={onOkClick}>
                    Ok
                </Button>
            </div>
        </div>
    );
};

export default InputAutoCompleteWithButton;
