/**
 * File responsible for the content when clicking `Audit` in management pages.
 */

import { Button, Col, DatePicker, Form, Modal, Row, Select } from 'antd';
import { API } from 'aws-amplify';
import { clone, forEach, get, includes, isEmpty, map } from 'lodash';
import moment from 'moment-timezone/moment-timezone';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    dateFormatDDMMMYYYYSpace,
    dateFormatYYYYMMDDDash,
} from '../../constants/dateFormats';
import queries from '../../graphql/queries.graphql';
import { getCompaniesAccessLogsRequestAction } from '../../store/support/actions';
import {
    getPopoverContainer,
    populatePopoverContainer,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { FILTER_SEARCH_DEBOUNCE_TIME } from '../common/InputAutoCompleteWithButton';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Item: FormItem } = Form;
const { Option } = Select;
let timeoutHandle: any = null;
let unmounted: boolean = false;
interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
}

const formItemNames = {
    START_DATE: 'StartDate',
    END_DATE: 'EndDate',
    USER: 'User',
};

const filterName = 'UserName';
const filterValueProp = 'UserId';
const labelFields = ['GivenName', 'FamilyName'];
const queryName = 'GET_SUPPORT_USERS_AUTOCOMPLETE_FILTER';
const queryResponseName = 'GetSupportUsers.Users';

const SupportAuditAll: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { getFieldDecorator, validateFields } = form;
    const [selectState, setSelectState] = useState<{
        data: [];
        fetching: boolean;
        value: string;
    }>({
        data: [],
        fetching: false,
        value: '',
    });

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmounted = false;

        //will unmount
        return () => {
            unmounted = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Common function for updating the select state.
     * @param selectStateObject - must conform to inputState object
     */
    const updateSelectState = (selectStateObject: {}) => {
        setSelectState({
            ...selectState,
            ...selectStateObject,
        });
    };

    /**
     * Function called when `Ok` button is clicked after successful access logs request.
     */
    const handleClosePanel = (refetchList: boolean) => {
        if (closePanel) closePanel(refetchList);
    };

    /**
     * Function for getting the options from query resopnse
     */
    const getDataOptions = (value: string) => {
        const queryVariables: DynamicObject = {
            // SortField: 'Name',
            // Ascending: true,
            // PageSize: 20,
            // Skip: 0,
        };

        queryVariables[filterName] = value;

        API.graphql({
            query: queries[queryName],
            variables: queryVariables,
        })
            .then((res: any) => {
                if (unmounted) return;
                const data: DynamicObject[] = [];
                forEach(
                    get(res, `data.${queryResponseName}`),
                    (opt: DynamicObject) => {
                        if (!includes(data, opt)) {
                            data.push(opt);
                        }
                    }
                );

                updateSelectState({ value, data, fetching: false });
            })
            .catch(() => {
                if (unmounted) return;
                updateSelectState({ value, data: [], fetching: false });
            });
    };

    /**
     * Function for calling the function to get the options from query
     */
    const fetchOptions = (value: string) => {
        if (value.length >= 3) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            timeoutHandle = setTimeout(() => {
                updateSelectState({ value, data: [], fetching: true });
                getDataOptions(value);
            }, FILTER_SEARCH_DEBOUNCE_TIME);
        } else {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            updateSelectState({ value, data: [], fetching: false });
        }
    };

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                const startValue = get(values, formItemNames.START_DATE);
                const endValue = get(values, formItemNames.END_DATE);

                if (
                    startValue &&
                    endValue &&
                    moment(endValue).isBefore(moment(startValue))
                ) {
                    Modal.error({
                        title: 'Error',
                        content: (
                            <div>
                                <b>Start date</b> should not be greater than{' '}
                                <b>End date</b>!
                            </div>
                        ),
                        getContainer: populatePopoverContainer(containerRef),
                    });
                } else {
                    setSubmitLoading(true);

                    const startDate = startValue
                        ? moment(startValue).format(dateFormatYYYYMMDDDash)
                        : null;
                    const endDate = endValue
                        ? moment(endValue).format(dateFormatYYYYMMDDDash)
                        : null;

                    const startDateWithTime = startDate
                        ? startDate + 'T00:00:00'
                        : null;
                    const endDateWithTime = endDate
                        ? endDate + 'T23:59:59'
                        : null;

                    const userFormValue = get(values, formItemNames.USER);
                    let userValue = clone(userFormValue);
                    if (userFormValue) {
                        if (userFormValue === 'All') {
                            userValue = undefined;
                        } else {
                            const parsedValue = JSON.parse(userFormValue);
                            userValue = get(parsedValue, filterValueProp);
                        }
                    }
                    dispatch(
                        getCompaniesAccessLogsRequestAction({
                            StartDate: startDateWithTime,
                            EndDate: endDateWithTime,
                            User: userValue,
                            callback: downloadReportResponseModal,
                        })
                    );
                }
            }
        });
    };

    /**
     * Function responsible for showing the response modal after getting the audit for access logs..
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const downloadReportResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Report generated successfully!',
                onOk: () => handleClosePanel(false),
                getContainer: () => getPopoverContainer(containerRef),
            });
        } else {
            let errorMessageContent: any = `Failed to generate report!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    /**
     * Function for getting all the user options.
     */
    const getUserOptions = () => {
        const optionsElemList: any = [];
        optionsElemList.push(
            <Option key="All" value="All">
                All
            </Option>
        );

        forEach(selectState.data, (data: any) => {
            const value = JSON.stringify(data);
            let label = '';
            forEach(labelFields, (lf: string) => {
                if (label !== '') label += ' ';
                label += data[lf] || '';
            });
            optionsElemList.push(
                <Option key={value} value={value}>
                    {label}
                </Option>
            );
        });

        return optionsElemList;
    };

    /**
     * Function for getting the initial value for access level (which is the Administrator Role Id).
     */
    const getUserInitialValue = () => {
        return 'All';
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        const { fetching } = selectState;
        return (
            <Form
                className="support-inline-form"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
            >
                <Row className="mb-10">
                    <Col>
                        <FormItem label="Start date">
                            {getFieldDecorator(formItemNames.START_DATE, {
                                initialValue: moment(),
                                // rules: [
                                //     {
                                //         required: true,
                                //         message: 'Start date required!',
                                //     },
                                // ],
                            })(
                                <DatePicker
                                    className="w-100"
                                    format={dateFormatDDMMMYYYYSpace}
                                    placeholder="Start date"
                                    getCalendarContainer={populatePopoverContainer(
                                        containerRef
                                    )}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem label="End date">
                            {getFieldDecorator(formItemNames.END_DATE, {
                                initialValue: moment(),
                                // rules: [
                                //     {
                                //         required: true,
                                //         message: 'End date required!',
                                //     },
                                // ],
                            })(
                                <DatePicker
                                    className="w-100"
                                    format={dateFormatDDMMMYYYYSpace}
                                    placeholder="End date"
                                    getCalendarContainer={populatePopoverContainer(
                                        containerRef
                                    )}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem label="User">
                            {getFieldDecorator(formItemNames.USER, {
                                initialValue: getUserInitialValue(),
                            })(
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    // showArrow={false}
                                    filterOption={false}
                                    onSearch={fetchOptions}
                                    // onChange={handleChange}
                                    // onSelect={handleSelect}
                                    notFoundContent={null}
                                    placeholder="Search"
                                    getPopupContainer={populatePopoverContainer(
                                        containerRef
                                    )}
                                    loading={fetching}
                                >
                                    {getUserOptions()}
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };

    if (!visible) {
        return null;
    }

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <Row>
                    <Col className="ta-right" span={24}>
                        <div>
                            <Button type="primary" onClick={handleSubmitForm}>
                                Download report
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Generating report"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while generating the report . . ."
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const SupportAuditAllForm = Form.create({
    name: 'support-audit-panel-form',
})(SupportAuditAll);

export default withDateFormatHandler(withRouter(SupportAuditAllForm));
