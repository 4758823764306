import { supportSortByOptions } from '../../constants/supportSortAndFilters';
import { PageData } from '../common/types';

export enum SupportActionTypes {
    GET_COMPANIES_REQUEST = '@@support/GET_COMPANIES_REQUEST',
    GET_COMPANIES_SUCCESS = '@@support/GET_COMPANIES_SUCCESS',
    GET_COMPANIES_ERROR = '@@support/GET_COMPANIES_ERROR',
    UPDATE_COMPANIES_FILTERS = '@@support/UPDATE_COMPANIES_FILTERS',
    UPDATE_COMPANIES_SORT_BY_STATE = '@@support/UPDATE_COMPANIES_SORT_BY_STATE',
    UPDATE_COMPANIES_TABLE_FILTER_STATE = '@@support/UPDATE_COMPANIES_TABLE_FILTER_STATE',

    CLEAR_COMPANIES_STATE_ALL_TABLE_FILTERS = '@@support/CLEAR_COMPANIES_STATE_ALL_TABLE_FILTERS',
    CLEAR_SUPPORT_STATE_DATA = '@@support/CLEAR_SUPPORT_STATE_DATA',
    SET_COMPANY_SELECTED_ID_REQUEST = '@@support/SET_COMPANY_SELECTED_ID_REQUEST',
    SET_COMPANY_SELECTED_ID_SUCCESS = '@@support/SET_COMPANY_SELECTED_ID_SUCCESS',
    GET_COMPANY_DATA_REQUEST = '@@support/GET_COMPANY_DATA_REQUEST',
    GET_COMPANY_DATA_SUCCESS = '@@support/GET_COMPANY_DATA_SUCCESS',
    GET_COMPANY_DATA_ERROR = '@@support/GET_COMPANY_DATA_ERROR',
    CLEAR_COMPANY_DATA_SUCCESS = '@@support/CLEAR_COMPANY_DATA_SUCCESS',

    GET_REGION_FILTER_OPTIONS_REQUEST = '@@support/GET_REGION_FILTER_OPTIONS_REQUEST',
    GET_REGION_FILTER_OPTIONS_RESPONSE = '@@support/GET_REGION_FILTER_OPTIONS_RESPONSE',

    GAIN_COMPANY_ACCESS_REQUEST = '@@support/GAIN_COMPANY_ACCESS_REQUEST',
    REMOVE_COMPANY_ACCESS_REQUEST = '@@support/REMOVE_COMPANY_ACCESS_REQUEST',
    GET_COMPANIES_ACCESS_LOGS_REQUEST = '@@support/GET_COMPANIES_ACCESS_LOGS_REQUEST',
    GET_COMPANY_ACCESS_LOGS_REQUEST = '@@support/GET_COMPANY_ACCESS_LOGS_REQUEST',
    GET_COMPANY_ACCESS_LOGS_SUCCESS = '@@support/GET_COMPANY_ACCESS_LOGS_SUCCESS',
    GET_COMPANY_ACCESS_LOGS_ERROR = '@@support/GET_COMPANY_ACCESS_LOGS_ERROR',
    UPDATE_COMPANY_ACCESS_LOGS_FILTERS = '@@support/UPDATE_COMPANY_ACCESS_LOGS_FILTERS',

    GET_COMPANY_WORKFLOW_OPTIONS_REQUEST = '@@support/GET_COMPANY_WORKFLOW_OPTIONS_REQUEST',
    GET_WORKFLOW_STEP_OPTIONS_REQUEST = '@@support/GET_WORKFLOW_STEP_OPTIONS_REQUEST',
    GET_WORKFLOW_STATE_FOR_COMPANY_REQUEST = '@@support/GET_WORKFLOW_STATE_FOR_COMPANY_REQUEST',
}

export interface SupportState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: Company[];
    readonly pageData: PageData;
    readonly filters: GetCompaniesRequestPayload['filters'];
    readonly sortBy: GetCompaniesRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly tableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record: {};
        companyAccessLogs: CompanyAccessLogsState;
        selectedId: string | null;
        errorMessages: string[];
    };
    readonly regionFilterOptions: string[];
    readonly regionFilterOptionsLoading: boolean;
}

export interface Company {
    readonly CompanyId: string;
    readonly Name: string;
    readonly LogoPath: string;
    readonly CreatedDateTime: string;
    readonly Region: string;
    readonly Country: string;
    readonly LanguageCode: string;
    readonly CompanyGroup: string;
}

export interface GetCompaniesRequestPayload {
    filters: {};
    sortBy: typeof supportSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

export interface CompanyAccessLogsState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: CompanyAccessLog[];
    readonly pageData: {
        pageSize: number;
        currentPage: number;
        paginationToken: null | string;
        hasNextPage: boolean;
    };
    readonly filters: GetCompanyAccessLogsRequestPayload['filters'];
}

export interface CompanyAccessLog {
    // Company: {
    //     Name: string;
    // };
    EventDateTime: string;
    User: {
        GivenName: string;
        FamilyName: string;
    };
    Action: string;
    Actor: string;
    ActionSucceeded: boolean;
}

export interface GetCompanyAccessLogsRequestPayload {
    filters: {};
    pageSize: number;
    currentPage: number;
}

export interface WorkflowOption {
    readonly WorkflowId: string;
    readonly WorkflowName: string;
}
