import { announcementsSortByOptions } from '../../constants/announcementsSortAndFilters';
import { PageData } from '../common/types';

export enum AnnouncementsActionTypes {
    GET_ANNOUNCEMENTS_REQUEST = '@@announcements/GET_ANNOUNCEMENTS_REQUEST',
    GET_ANNOUNCEMENTS_SUCCESS = '@@announcements/GET_ANNOUNCEMENTS_SUCCESS',
    GET_ANNOUNCEMENTS_ERROR = '@@announcements/GET_ANNOUNCEMENTS_ERROR',
    UPDATE_ANNOUNCEMENTS_FILTERS = '@@announcements/UPDATE_ANNOUNCEMENTS_FILTERS',
    UPDATE_ANNOUNCEMENTS_SORT_BY = '@@announcements/UPDATE_ANNOUNCEMENTS_SORT_BY',
    UPDATE_ANNOUNCEMENTS_TABLE_FILTER_STATE = '@@announcements/UPDATE_ANNOUNCEMENTS_TABLE_FILTER_STATE',

    CLEAR_ANNOUNCEMENTS_STATE_ALL_TABLE_FILTERS = '@@announcements/CLEAR_ANNOUNCEMENTS_STATE_ALL_TABLE_FILTERS',
    CLEAR_ANNOUNCEMENTS_STATE_DATA = '@@announcements/CLEAR_ANNOUNCEMENTS_STATE_DATA',
    SET_ANNOUNCEMENT_SELECTED_ID_REQUEST = '@@announcements/SET_ANNOUNCEMENT_SELECTED_ID_REQUEST',
    SET_ANNOUNCEMENT_SELECTED_ID_SUCCESS = '@@announcements/SET_ANNOUNCEMENT_SELECTED_ID_SUCCESS',
    GET_ANNOUNCEMENT_DATA_REQUEST = '@@announcements/GET_ANNOUNCEMENT_DATA_REQUEST',
    GET_ANNOUNCEMENT_DATA_SUCCESS = '@@announcements/GET_ANNOUNCEMENT_DATA_SUCCESS',
    GET_ANNOUNCEMENT_DATA_ERROR = '@@announcements/GET_ANNOUNCEMENT_DATA_ERROR',
    CLEAR_ANNOUNCEMENT_DATA_SUCCESS = '@@announcements/CLEAR_ANNOUNCEMENT_DATA_SUCCESS',

    SAVE_ANNOUNCEMENT_REQUEST = '@@announcements/SAVE_ANNOUNCEMENT_REQUEST',
    DELETE_ANNOUNCEMENT_REQUEST = '@@announcements/DELETE_ANNOUNCEMENT_REQUEST',
}

export interface AnnouncementsState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: Announcement[];
    readonly pageData: PageData;
    readonly filters: GetAnnouncementsRequestPayload['filters'];
    readonly sortBy: GetAnnouncementsRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly tableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record: {};
        selectedId: string | null;
        errorMessages: string[];
    };
    readonly regionFilterOptions: string[];
    readonly regionFilterOptionsLoading: boolean;
}

export interface Announcement {
    readonly ProductAnnouncementId: string;
    readonly Tag: string;
    readonly Title: string;
    readonly Body: string;
    readonly PublishDateTime: string;
}

export interface GetAnnouncementsRequestPayload {
    filters: {};
    sortBy: typeof announcementsSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}
