import { handleReduxErrorAction } from './../common/actions';
import API, { graphqlOperation } from '@aws-amplify/api';
import { get, isEmpty } from 'lodash';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    DEFAULT_REGION_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { SUPPORT_PAGE } from '../../config/tableAndPageConstants';
// import { ApplicationState } from '..';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    getGraphqlQueryString,
    getRegionConfigFromList,
    getSortFieldsWithCustomFields,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    getCompaniesErrorAction,
    getCompaniesRequestAction,
    getCompaniesSuccessAction,
    getCompanyAccessLogsErrorAction,
    getCompanyAccessLogsSuccessAction,
    getCompanyDataErrorAction,
    getCompanyDataSuccessAction,
    getRegionFilterOptionsRequestAction,
    getRegionFilterOptionsResponseAction,
    setSelectedCompanyIdSuccessAction,
} from './actions';
import { SupportActionTypes, WorkflowOption } from './types';
import { Auth } from 'aws-amplify';
import { getRegionKeyConfig, getRegionSettingConfig } from '../auth/sagas';

export const getCompanyData = (state: ApplicationState) =>
    state.support.activeData;

export const getSelectedCompanyId = (state: ApplicationState) =>
    state.support.activeData.selectedId;

export const getSelectedCompanyRegion = (state: ApplicationState) =>
    get(state.support.activeData, 'record.Region', DEFAULT_REGION_NAME);

let refetchCount = 0;
/**
 * Function that fetches the company list - api connection.
 * @param param0
 */
function* handleGetCompaniesRequest({ payload }: any) {
    const errorMessage = 'Error fetching company list. Please try again later.';
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const sortObject = getSortFieldsWithCustomFields(sortBy);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_COMPANIES, {
                ...cleanFilters,
                // SortField: sortBy,
                ...sortObject,
                Ascending: sortAscending,
                // PageSize: pageSize,
                // Skip: currentPage * SUPPORT_PAGE.pageSize,
            })
        );

        const { Companies } = get(res.data, 'GetCompanies');
        if (Companies) {
            const responsePayload = {
                data: Companies,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Companies.length < pageSize) &&
                        !(pageSize < SUPPORT_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getCompaniesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getCompaniesRequestAction(payload));
        } else {
            yield put(getCompaniesErrorAction([errorMessage]));
        }
    }
}

/**
 * Function that calls the API for getting the company details based on the given Id.
 * @param param0
 */
function* handleGetCompanyDataRequest({ payload: { companyId } }: any) {
    const errorMessage =
        'Error fetching company details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_COMPANY_DETAILS, {
                CompanyId: companyId,
            })
        );

        const Company = get(res.data, 'GetCompanyDetails');
        if (Company) {
            const responsePayload = {
                record: Company,
            };

            yield put(getCompanyDataSuccessAction(responsePayload));
        } else {
            yield put(getCompanyDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCompanyDataErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the selected support id in the redux state for reference.
 * @param param0
 */
function* handleSetSelectedCompanyIdRequest({ payload }: any) {
    const { companyId, callback } = payload;
    yield put(setSelectedCompanyIdSuccessAction(companyId));
    callback();
}

/**
 * Function for getting the region filter options.
 */
function* handleGetRegionFilterOptionsRequest() {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REGIONS)
        );

        if (res.error) {
            console.log('res.error', res.error);
        } else {
            refetchCount = 0;

            yield put(
                getRegionFilterOptionsResponseAction(res.data.GetRegions)
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getRegionFilterOptionsRequestAction());
        } else {
            getRegionFilterOptionsResponseAction([]);
        }
    }
}

/**
 * Function called for requesting company access.
 * @param param0
 */
function* handleGainCompanyAccessRequest({ payload: sagaPayload }: any) {
    const { CompanyId, Role, callback } = sagaPayload;

    const payload = {
        CompanyId,
        RoleId: Role,
    };

    try {
        yield call([API, 'post'], API_NAME, '/company/access', {
            body: payload,
        });
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for removing company access.
 * @param param0
 */
function* handleRemoveCompanyAccessRequest({ payload: sagaPayload }: any) {
    const { CompanyId, callback } = sagaPayload;

    const payload = {
        CompanyId,
    };

    try {
        yield call([API, 'post'], API_NAME, '/company/removeaccess', {
            body: payload,
        });
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for getting company access logs.
 * @param param0
 */
function* handleGetCompaniesAccessLogsRequest({ payload: sagaPayload }: any) {
    const { StartDate, EndDate, User, callback } = sagaPayload;

    const payload = {
        UserId: User,
        EventDateTimeMax: EndDate,
        EventDateTimeMin: StartDate,
    };

    try {
        yield call([API, 'post'], API_NAME, '/report/companyaccess', {
            body: payload,
        });
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for getting company access logs.
 * @param param0
 */
function* handleGetCompanyAccessLogsRequest({ payload: sagaPayload }: any) {
    const errorMessage = `Error fetching company access logs. Please try again later.`;
    try {
        const { filters, pageSize, paginationToken, currentPage } = sagaPayload;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_COMPANY_ACCESS_LOGS, {
                ...cleanFilters,
                PageSize: pageSize,
                PaginationToken: paginationToken,
            })
        );

        const { CompanyAccessLogs, PaginationToken } = get(
            res.data,
            'GetCompanyAccessLogs'
        );

        if (CompanyAccessLogs) {
            const responsePayload = {
                data: CompanyAccessLogs,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    paginationToken: PaginationToken,
                    hasNextPage: !isEmpty(PaginationToken),
                },
            };

            yield put(getCompanyAccessLogsSuccessAction(responsePayload));
        } else {
            const responsePayload = {
                data: [],
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    paginationToken: PaginationToken,
                    hasNextPage: false,
                },
            };

            yield put(getCompanyAccessLogsSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCompanyAccessLogsErrorAction([errorMessage]));
    }
}

function* getRegionConfigUsed() {
    const regionKeyConfig: DynamicObject[] = yield select(getRegionKeyConfig);
    const regionSettingsConfig: DynamicObject[] = yield select(
        getRegionSettingConfig
    );
    const region: string = yield select(getSelectedCompanyRegion);

    return getRegionConfigFromList(
        region,
        regionKeyConfig,
        regionSettingsConfig
    );
}

/**
 * Function responsible for getting the company workflow names.
 */
function* handleGetCompanyWorkflowOptionsRequest({
    payload: sagaPayload,
}: any) {
    const { companyId, callback } = sagaPayload;
    try {
        const configUsed: DynamicObject = yield call(getRegionConfigUsed);
        const currentSession: DynamicObject = yield Auth.currentSession();
        const accessToken = get(currentSession, 'accessToken.jwtToken');
        const customResponse: DynamicObject = yield fetch(
            `${get(configUsed, 'Url')}/graphql`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessToken,
                },
                body: JSON.stringify({
                    query: getGraphqlQueryString(
                        queries.GET_WORKFLOW_NAMES_FOR_COMPANY
                    ),
                    variables: {
                        CompanyId: companyId,
                    },
                }),
            }
        );

        const res: DynamicObject = yield customResponse.json();

        const workflowOptions: WorkflowOption[] = get(
            res,
            'data.GetWorkflowNamesForCompany'
        );

        callback(workflowOptions);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        callback([]); // Empty if error
    }
}

/**
 * Function called for getting the workflow step options.
 * @param param0
 */
function* handleGetWorkflowStepOptionsRequest({ payload: sagaPayload }: any) {
    try {
        const { companyId, workflowId, callback } = sagaPayload;
        const configUsed: DynamicObject = yield call(getRegionConfigUsed);

        const currentSession: DynamicObject = yield Auth.currentSession();
        const accessToken = get(currentSession, 'accessToken.jwtToken');
        const customResponse: DynamicObject = yield fetch(
            `${get(configUsed, 'Url')}/graphql`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessToken,
                },
                body: JSON.stringify({
                    query: getGraphqlQueryString(
                        queries.GET_WORKFLOW_STEPS_FOR_COMPANY
                    ),
                    variables: {
                        CompanyId: companyId,
                        WorkflowId: workflowId,
                    },
                }),
            }
        );

        const res: DynamicObject = yield customResponse.json();

        const workflowSteps = get(res.data, 'GetWorkflowStepsForCompany');

        if (callback) callback(workflowSteps);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for getting the workflow step information.
 * @param param0
 */
function* handleGetWorkflowStateForCompanyRequest({
    payload: sagaPayload,
}: any) {
    try {
        const { companyId, workflowId, workflowStateName, callback } =
            sagaPayload;
        const configUsed: DynamicObject = yield call(getRegionConfigUsed);

        const currentSession: DynamicObject = yield Auth.currentSession();
        const accessToken = get(currentSession, 'accessToken.jwtToken');
        const customResponse: DynamicObject = yield fetch(
            `${get(configUsed, 'Url')}/graphql`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessToken,
                },
                body: JSON.stringify({
                    query: getGraphqlQueryString(
                        queries.GET_WORKFLOW_STATE_FOR_COMPANY
                    ),
                    variables: {
                        CompanyId: companyId,
                        WorkflowId: workflowId,
                        Name: workflowStateName,
                    },
                }),
            }
        );

        const res: DynamicObject = yield customResponse.json();

        const workflowStateInformation = get(
            res.data,
            'GetWorkflowStateForCompany'
        );

        if (callback) callback(workflowStateInformation);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetCompaniesRequest() {
    yield takeLatest(
        SupportActionTypes.GET_COMPANIES_REQUEST,
        handleGetCompaniesRequest
    );
}

function* watchGetSupportDataRequest() {
    yield takeLatest(
        SupportActionTypes.GET_COMPANY_DATA_REQUEST,
        handleGetCompanyDataRequest
    );
}

function* watchSetSelectedSupportIdRequest() {
    yield takeLatest(
        SupportActionTypes.SET_COMPANY_SELECTED_ID_REQUEST,
        handleSetSelectedCompanyIdRequest
    );
}

function* watchGetRegionActionFilterOptionsRequest() {
    yield takeLatest(
        SupportActionTypes.GET_REGION_FILTER_OPTIONS_REQUEST,
        handleGetRegionFilterOptionsRequest
    );
}

function* watchGainCompanyAccessRequest() {
    yield takeLatest(
        SupportActionTypes.GAIN_COMPANY_ACCESS_REQUEST,
        handleGainCompanyAccessRequest
    );
}

function* watchRemoveCompanyAccessRequest() {
    yield takeLatest(
        SupportActionTypes.REMOVE_COMPANY_ACCESS_REQUEST,
        handleRemoveCompanyAccessRequest
    );
}

function* watchGetCompaniesAccessLogsRequest() {
    yield takeLatest(
        SupportActionTypes.GET_COMPANIES_ACCESS_LOGS_REQUEST,
        handleGetCompaniesAccessLogsRequest
    );
}

function* watchGetCompanyAccessLogsRequest() {
    yield takeLatest(
        SupportActionTypes.GET_COMPANY_ACCESS_LOGS_REQUEST,
        handleGetCompanyAccessLogsRequest
    );
}

function* watchGetCompanyWorkflowOptionsRequest() {
    yield takeLatest(
        SupportActionTypes.GET_COMPANY_WORKFLOW_OPTIONS_REQUEST,
        handleGetCompanyWorkflowOptionsRequest
    );
}

function* watchGetWorkflowStepOptionsRequest() {
    yield takeLatest(
        SupportActionTypes.GET_WORKFLOW_STEP_OPTIONS_REQUEST,
        handleGetWorkflowStepOptionsRequest
    );
}

function* watchGetWorkflowStateForCompanyRequest() {
    yield takeLatest(
        SupportActionTypes.GET_WORKFLOW_STATE_FOR_COMPANY_REQUEST,
        handleGetWorkflowStateForCompanyRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* supportSaga() {
    yield all([
        fork(watchGetCompaniesRequest),
        fork(watchGetSupportDataRequest),
        fork(watchSetSelectedSupportIdRequest),
        fork(watchGetRegionActionFilterOptionsRequest),
        fork(watchGainCompanyAccessRequest),
        fork(watchRemoveCompanyAccessRequest),
        fork(watchGetCompaniesAccessLogsRequest),
        fork(watchGetCompanyAccessLogsRequest),
        fork(watchGetCompanyWorkflowOptionsRequest),
        fork(watchGetWorkflowStepOptionsRequest),
        fork(watchGetWorkflowStateForCompanyRequest),
    ]);
}

export default supportSaga;
