import moment from 'moment-timezone';

export const announcementsSortValues = {
    ANNOUNCEMENT_TITLE: 'Title',
    ANNOUNCEMENT_TAG: 'Tag',
    ANNOUNCEMENT_CREATED_DATETIME: 'CreatedDateTime',
    ANNOUNCEMENT_PUBLISH_DATETIME: 'PublishDateTime',
};

export const preConfiguredFilters = {
    ALL_ANNOUNCEMENTS: 'All announcements',
};

export const announcementTableFilterOptions = [
    // { label: 'All open announcement', value: 'Open' },
    {
        label: 'All announcements',
        value: preConfiguredFilters.ALL_ANNOUNCEMENTS,
    },
];

export const announcementsSortByOptions = [
    { label: 'Title', value: announcementsSortValues.ANNOUNCEMENT_TITLE },
    { label: 'Tag', value: announcementsSortValues.ANNOUNCEMENT_TAG },
    {
        label: 'Created datetime',
        value: announcementsSortValues.ANNOUNCEMENT_CREATED_DATETIME,
    },
    {
        label: 'Publish datetime',
        value: announcementsSortValues.ANNOUNCEMENT_PUBLISH_DATETIME,
    },
];

export const announcementTagTypes = {
    FEATURE: 'Feature',
    IMPROVEMENT: 'Improvement',
};

export const announcementTagOptions = [
    {
        label: announcementTagTypes.FEATURE,
        value: 1,
    },
    {
        label: announcementTagTypes.IMPROVEMENT,
        value: 2,
    },
];

export const formFieldNames = {
    TAG: 'Tag',
    TAG_LABEL: 'Tag',
    PUBLISH_DATETIME: 'PublishDateTime',
    PUBLISH_DATETIME_LABEL: 'Publish datetime',
    TITLE: 'Title',
    TITLE_LABEL: 'Title',
    BODY: 'Body',
    BODY_LABEL: 'Content',
};

export const initialValues = {
    TAG: announcementTagTypes.FEATURE,
    PUBLISH_DATETIME: moment(),
    TITLE: '',
    BODY: '',
};

export const quillEditorColors = [
    '#e30e84',
    '#000000a6',
    '#e60000',
    '#ff9900',
    '#ffff00',
    '#008a00',
    '#0066cc',
    '#9933ff',
    '#ffffff',
    '#facccc',
    '#ffebcc',
    '#cce8cc',
    '#cce0f5',
    '#ebd6ff',
    '#bbbbbb',
    '#f06666',
    '#ffc266',
    '#ffff66',
    '#66b966',
    '#66a3e0',
    '#c285ff',
    '#888888',
    '#a10000',
    '#b26b00',
    '#b2b200',
    '#006100',
    '#0047b2',
    '#6b24b2',
    '#000000',
    '#5c0000',
    '#663d00',
    '#666600',
    '#003700',
    '#002966',
    '#3d1466',
];
