import { action } from 'typesafe-actions';
import {
    ResponseModalObject,
    DynamicObject,
} from '../../utils/commonInterfaces';
import { CommonFiltersActionTypes, PageView } from './types';

/**
 * Action called for triggering the saga for saving the Applied filters view.
 * @param payload
 * @param callback - function called after saving - useful for refetching the company details
 * and showing alert modals
 */
export const saveAppliedFiltersViewAction = (
    payload: any,
    callback?: (response: ResponseModalObject) => void
) =>
    action(CommonFiltersActionTypes.SAVE_APPLIED_FILTERS_VIEW, {
        payload,
        callback,
    });

/**
 * Action called for triggering the saga for saving the Applied filters view.
 * @param payload
 * @param callback - function called after saving - useful for refetching the company details
 * and showing alert modals
 */
export const deleteAppliedFiltersViewAction = (
    payload: { Id: string; PageName: string | undefined },
    callback?: (response: ResponseModalObject) => void
) =>
    action(CommonFiltersActionTypes.DELETE_APPLIED_FILTERS_VIEW, {
        payload,
        callback,
    });

/**
 * Action for fetching the Page Views (table filters) per page
 * @param pageName - page on where to get the Page Views from
 * @param isDashboard - determines if the result is for dashboard / view
 * @param callback - callback function that handles the returned pageview list
 */
export const fetchPageAppliedFiltersViewListRequestAction = (
    pageName: string,
    isDashboard: boolean,
    callback?: (data: PageView[]) => void
) =>
    action(
        CommonFiltersActionTypes.FETCH_PAGE_APPLIED_FILTERS_VIEW_LIST_REQUEST,
        {
            pageName,
            isDashboard,
            callback,
        }
    );

/**
 * Function that updates the refetch page view indicator.
 * @param willRefetch - boolean indicator to refetch or not
 * @param defaultPageViewValue - optional value, the latest added filter
 */
export const updateRefetchPageViewsAction = (
    willRefetch: boolean,
    defaultPageViewValue?: string
) =>
    action(CommonFiltersActionTypes.UPDATE_REFETCH_PAGE_VIEWS, {
        willRefetch,
        defaultPageViewValue,
    });

/**
 * Action called for triggering the saga for getting the presigned url for uploading the attachment.
 * @param payload
 * @param callback - function called after saving
 */
export const getAttachmentPresignedUrlAction = (
    payload: DynamicObject,
    callback?: (response: ResponseModalObject) => void
) =>
    action(CommonFiltersActionTypes.GET_ATTACHMENT_PRESIGNED_URL, {
        payload,
        callback,
    });

/**
 * Action called for triggering the saga for deleting the attachment.
 * @param payload
 * @param callback - function called after saving
 */
export const deleteAttachmentAction = (
    payload: DynamicObject,
    callback?: (response: ResponseModalObject) => void
) =>
    action(CommonFiltersActionTypes.DELETE_ATTACHMENT, {
        payload,
        callback,
    });

/**
 * Function that updates the refetch page view indicator.
 * @param isEditing - boolean indicator if attachment is editing or not
 */
export const updateAttachmentEditingAction = (isEditing: boolean) =>
    action(CommonFiltersActionTypes.UPDATE_ATTACHMENT_EDITING, isEditing);

/**
 * Function that updates the locale redux state.
 * @param locale
 */
export const setLocaleAction = (locale: string) =>
    action(CommonFiltersActionTypes.SET_LOCALE, locale);

/**
 * Common action for handling redux error
 */
export const handleReduxErrorAction = (err: any) =>
    action(CommonFiltersActionTypes.HANDLE_REDUX_ERROR, err);
