/**
 * Component that populates the item for each row in Support page.
 */

import { Checkbox, Col, Row } from 'antd';
import { includes, isEqual } from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedCompanyId } from '../../store/support/sagas';
import { Company } from '../../store/support/types';
import { getAssetsPath } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import ImageWithLoading from '../common/ImageWithLoading';

const assetsPath = getAssetsPath();
const imgPlaceholder = `${assetsPath}/imagePlaceholder.png`;

interface IProps {
    readonly item: Company;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}
const SupportItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatDateUTCToLocal,
}: IProps) => {
    const selectedId = useSelector(getSelectedCompanyId);

    const {
        CompanyId: Id,
        LogoPath,
        Name,
        Region,
        LanguageCode,
        CreatedDateTime,
        CompanyGroup,
    } = item;

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <Row
                    className="fx-center-xl center-flex-i pr-8 h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                >
                    {/* Commented xl sizes are when icon is added */}
                    <Col
                        // xl={3}
                        xl={1}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                    >
                        <Row className="fx-center-xl">
                            <Col
                                // xl={12}
                                xl={24}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                <div className="ta-center center-flex-all">
                                    <Checkbox
                                        checked={includes(selectedRowKeys, Id)}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        // xl={21}
                        xl={23}
                        lg={22}
                        md={22}
                        sm={22}
                        xs={22}
                        className="h-100"
                    >
                        <Row className="flex-i h-100">
                            <Col
                                xl={3}
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="center-flex-all"
                            >
                                {includes(LogoPath, 'https://') ||
                                includes(LogoPath, 'http://') ? (
                                    <ImageWithLoading
                                        src={LogoPath || imgPlaceholder}
                                        className="support-company-logo"
                                    />
                                ) : (
                                    <img
                                        className="support-company-logo"
                                        alt=""
                                        src={imgPlaceholder}
                                    />
                                )}
                            </Col>
                            <Col
                                xl={9}
                                lg={9}
                                md={9}
                                sm={8}
                                xs={8}
                                className="support-item-col pl-30"
                            >
                                <Row>
                                    <Col>
                                        <b>{Name}</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>{CompanyGroup}</Col>
                                </Row>
                            </Col>
                            <Col
                                xl={4}
                                lg={4}
                                md={4}
                                sm={4}
                                xs={4}
                                className="support-item-col"
                            >
                                <Row>
                                    <Col>
                                        <b>{Region}</b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>{LanguageCode}</Col>
                                </Row>
                            </Col>
                            <Col
                                xl={8}
                                lg={8}
                                md={8}
                                sm={6}
                                xs={6}
                                className="ta-right support-item-col"
                            >
                                <Row>
                                    <Col>
                                        <b>
                                            Created{' '}
                                            {formatDateUTCToLocal(
                                                CreatedDateTime
                                            )}
                                        </b>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>&nbsp;</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* End of invoice item */}
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(withDateFormatHandler(SupportItemComponent), arePropsEqual);
