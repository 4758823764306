import { DETAILS_TAB } from './../../config/tableAndPageConstants';
import update from 'immutability-helper';
import { concat, isEqual, uniqWith } from 'lodash';
import { Reducer } from 'redux';
import { SUPPORT_PAGE } from '../../config/tableAndPageConstants';
import { supportSortByOptions } from '../../constants/supportSortAndFilters';
import { SupportActionTypes, SupportState } from './types';

// Type-safe initialState!
export const initialState: SupportState = {
    loading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: SUPPORT_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {},
    tableFilter: undefined,
    sortBy: supportSortByOptions[0].value, // 2 for Company Name
    sortAscending: true,
    regionFilterOptions: [],
    regionFilterOptionsLoading: false,
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
        companyAccessLogs: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.AUDIT.pageSize,
                currentPage: 0,
                paginationToken: null,
                hasNextPage: false,
            },
            filters: {},
        },
    },
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<SupportState> = (state = initialState, action) => {
    switch (action.type) {
        case SupportActionTypes.GET_COMPANIES_REQUEST: {
            return { ...state, loading: true };
        }
        case SupportActionTypes.GET_COMPANIES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.data, {
                    $set: action.payload.data,
                });
            } else {
                // update(state.data, {
                //     $push: action.payload.data,
                // });
                newDataState = uniqWith(
                    concat(state.data, action.payload.data),
                    isEqual
                );
            }
            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case SupportActionTypes.GET_COMPANIES_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        case SupportActionTypes.UPDATE_COMPANIES_FILTERS: {
            return { ...state, filters: action.payload };
        }
        case SupportActionTypes.UPDATE_COMPANIES_SORT_BY_STATE: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending,
                supportState: action.payload.supportState,
            };
        }
        case SupportActionTypes.UPDATE_COMPANIES_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }
        case SupportActionTypes.CLEAR_COMPANIES_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                tableFilter: initialState.tableFilter,
            };
        }
        case SupportActionTypes.CLEAR_SUPPORT_STATE_DATA: {
            return {
                ...initialState,
            };
        }

        //Single Record
        case SupportActionTypes.SET_COMPANY_SELECTED_ID_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                    },
                },
            });

            return newState;
        }

        case SupportActionTypes.SET_COMPANY_SELECTED_ID_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return newState;
        }

        case SupportActionTypes.GET_COMPANY_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: true,
                    },
                },
            });

            return newState;
        }

        case SupportActionTypes.GET_COMPANY_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages: initialState.activeData.errorMessages,
                    },
                },
            });

            return newState;
        }

        case SupportActionTypes.GET_COMPANY_DATA_ERROR: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return newState;
        }

        case SupportActionTypes.CLEAR_COMPANY_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        case SupportActionTypes.GET_REGION_FILTER_OPTIONS_REQUEST: {
            return {
                ...state,
                regionFilterOptionsLoading: true,
            };
        }
        case SupportActionTypes.GET_REGION_FILTER_OPTIONS_RESPONSE: {
            return {
                ...state,
                regionFilterOptionsLoading: false,
                regionFilterOptions: action.payload,
            };
        }

        // For company access logs reducers
        case SupportActionTypes.GET_COMPANY_ACCESS_LOGS_REQUEST: {
            const newCompanyAccessLogsState = update(
                state.activeData.companyAccessLogs,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                companyAccessLogs: { $set: newCompanyAccessLogsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SupportActionTypes.GET_COMPANY_ACCESS_LOGS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.companyAccessLogs.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.companyAccessLogs.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newCompanyAccessLogsState = update(
                state.activeData.companyAccessLogs,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.activeData.companyAccessLogs
                                .errorMessages,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                companyAccessLogs: { $set: newCompanyAccessLogsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SupportActionTypes.GET_COMPANY_ACCESS_LOGS_ERROR: {
            const newCompanyAccessLogsState = update(
                state.activeData.companyAccessLogs,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeData.companyAccessLogs.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                companyAccessLogs: { $set: newCompanyAccessLogsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SupportActionTypes.UPDATE_COMPANY_ACCESS_LOGS_FILTERS: {
            const newCompanyAccessLogsState = update(
                state.activeData.companyAccessLogs,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                companyAccessLogs: { $set: newCompanyAccessLogsState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as supportReducer };
