/**
 * Component that populates the item for each row in Announcements page.
 */

import { Checkbox, Col, Row } from 'antd';
import { get, includes, isEqual } from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { formFieldNames } from '../../constants/announcementsSortAndFilters';
import { dateFormatDoMMMYYYYhmmASpace } from '../../constants/dateFormats';
import { keyIndexName } from '../../pages/announcements/AnnouncementsManagementPage';
import { getSelectedAnnouncementId } from '../../store/announcements/sagas';
import { Announcement } from '../../store/announcements/types';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import AnnouncementTag from './AnnouncementTag';

interface IProps {
    readonly item: Announcement;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}
const AnnouncementItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatDateUTCToLocal,
}: IProps) => {
    const selectedId = useSelector(getSelectedAnnouncementId);

    const { [keyIndexName]: Id } = item;

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <Row
                    className="fx-center-xl center-flex-i pr-8 h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                >
                    {/* Commented xl sizes are when icon is added */}
                    <Col
                        // xl={3}
                        xl={1}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                    >
                        <Row className="fx-center-xl">
                            <Col
                                // xl={12}
                                xl={24}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                <div className="ta-center center-flex-all">
                                    <Checkbox
                                        checked={includes(selectedRowKeys, Id)}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        // xl={21}
                        xl={23}
                        lg={22}
                        md={22}
                        sm={22}
                        xs={22}
                        className="h-100"
                    >
                        <Row className="h-100" type="flex" align="middle">
                            <Col xl={3} lg={4} md={4} sm={4} xs={4}>
                                <AnnouncementTag
                                    tagValue={get(item, formFieldNames.TAG)}
                                />
                            </Col>
                            <Col
                                xl={13}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="announcements-item-col"
                            >
                                <b>{get(item, formFieldNames.TITLE)}</b>
                            </Col>
                            <Col
                                xl={8}
                                lg={8}
                                md={8}
                                sm={8}
                                xs={8}
                                className="ta-right announcements-item-col"
                            >
                                <Row>
                                    <Col>
                                        Publish on{' '}
                                        <b>
                                            <span>
                                                {get(
                                                    item,
                                                    formFieldNames.PUBLISH_DATETIME
                                                ) &&
                                                    formatDateUTCToLocal(
                                                        get(
                                                            item,
                                                            formFieldNames.PUBLISH_DATETIME
                                                        ),
                                                        null,
                                                        dateFormatDoMMMYYYYhmmASpace
                                                    )}
                                            </span>
                                        </b>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* End of announcement item */}
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(AnnouncementItemComponent),
    arePropsEqual
);
