import API, { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { ANNOUNCEMENTS_PAGE } from '../../config/tableAndPageConstants';
import queries from '../../graphql/queries.graphql';
import { checkShouldRequestRefetch } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { handleReduxErrorAction } from './../common/actions';
import {
    getAnnouncementDataErrorAction,
    getAnnouncementDataSuccessAction,
    getAnnouncementsErrorAction,
    getAnnouncementsRequestAction,
    getAnnouncementsSuccessAction,
    setSelectedAnnouncementIdSuccessAction,
} from './actions';
import { AnnouncementsActionTypes } from './types';

export const getAnnouncementData = (state: ApplicationState) =>
    state.announcements.activeData;

export const getSelectedAnnouncementId = (state: ApplicationState) =>
    state.announcements.activeData.selectedId;

let refetchCount = 0;
/**
 * Function that fetches the announcement list - api connection.
 * @param param0
 */
function* handleGetAnnouncementsRequest({ payload }: any) {
    const errorMessage =
        'Error fetching announcement list. Please try again later.';
    try {
        const { sortBy, sortAscending, pageSize, currentPage } = payload;
        // const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PRODUCT_ANNOUNCEMENTS, {
                // ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * ANNOUNCEMENTS_PAGE.pageSize,
            })
        );

        const { ProductAnnouncements: Announcements } = get(
            res.data,
            'GetProductAnnouncements'
        );

        if (Announcements) {
            const responsePayload = {
                data: Announcements,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Announcements.length < pageSize) &&
                        !(pageSize < ANNOUNCEMENTS_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getAnnouncementsSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getAnnouncementsRequestAction(payload));
        } else {
            yield put(getAnnouncementsErrorAction([errorMessage]));
        }
    }
}

/**
 * Function that calls the API for getting the announcement details based on the given Id.
 * @param param0
 */
function* handleGetAnnouncementDataRequest({
    payload: { announcementId },
}: any) {
    const errorMessage =
        'Error fetching announcement details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_ANNOUNCEMENT_DETAILS, {
                AnnouncementId: announcementId,
            })
        );

        const Announcement = get(res.data, 'GetAnnouncementDetails');
        if (Announcement) {
            const responsePayload = {
                record: Announcement,
            };

            yield put(getAnnouncementDataSuccessAction(responsePayload));
        } else {
            yield put(getAnnouncementDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getAnnouncementDataErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the selected announcements id in the redux state for reference.
 * @param param0
 */
function* handleSetSelectedAnnouncementIdRequest({ payload }: any) {
    const { announcementId, callback } = payload;
    yield put(setSelectedAnnouncementIdSuccessAction(announcementId));
    callback();
}

/**
 * Function called for saving the announcement.
 */
function* handleSaveAnnouncementRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/productannouncement/save',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for deleting the announcement.
 */
function* handleDeleteAnnouncementRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        yield call([API, 'post'], API_NAME, '/productannouncement/remove', {
            body: payload,
        });
        const returnData = {
            IsSuccess: true,
        };

        if (callback) callback(returnData);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetAnnouncementsRequest() {
    yield takeLatest(
        AnnouncementsActionTypes.GET_ANNOUNCEMENTS_REQUEST,
        handleGetAnnouncementsRequest
    );
}

function* watchGetAnnouncementDataRequest() {
    yield takeLatest(
        AnnouncementsActionTypes.GET_ANNOUNCEMENT_DATA_REQUEST,
        handleGetAnnouncementDataRequest
    );
}

function* watchSetSelectedAnnouncementIdRequest() {
    yield takeLatest(
        AnnouncementsActionTypes.SET_ANNOUNCEMENT_SELECTED_ID_REQUEST,
        handleSetSelectedAnnouncementIdRequest
    );
}

function* watchSaveAnnouncementRequest() {
    yield takeLatest(
        AnnouncementsActionTypes.SAVE_ANNOUNCEMENT_REQUEST,
        handleSaveAnnouncementRequest
    );
}

function* watchDeleteAnnouncementRequest() {
    yield takeLatest(
        AnnouncementsActionTypes.DELETE_ANNOUNCEMENT_REQUEST,
        handleDeleteAnnouncementRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* announcementsSaga() {
    yield all([
        fork(watchGetAnnouncementsRequest),
        fork(watchGetAnnouncementDataRequest),
        fork(watchSetSelectedAnnouncementIdRequest),
        fork(watchSaveAnnouncementRequest),
        fork(watchDeleteAnnouncementRequest),
    ]);
}

export default announcementsSaga;
