export const SUPPORT_PAGE = {
    pageSize: 20,
    rowHeight: 100,
};

export const ANNOUNCEMENTS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const PAGE_NAMES_FOR_VIEW = {
    SUPPORT_PAGE: 'SupportPage',
    ANNOUNCEMENTS_PAGE: 'AnnouncementsPage',
};

export const DETAILS_TAB = {
    AUDIT: {
        pageSize: 20,
        rowHeight: 70,
    },
};
