import API from '@aws-amplify/api';
import { get, includes } from 'lodash';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import { API_NAME } from '../../config/config';
import { DynamicObject } from '../../utils/commonInterfaces';
import { CommonFiltersActionTypes } from './types';
import config from '../../AmplifyConfig';
import { handleReduxErrorAction } from './actions';
import Modal from 'antd/lib/modal/Modal';
import { PAGE_NAMES_FOR_VIEW } from '../../config/tableAndPageConstants';

export const getDefaultPageViewValue = (state: ApplicationState) =>
    state.app.defaultPageViewValue;

export const getIsAttachmentEditingActive = (state: ApplicationState) =>
    state.app.attachmentEditing;

export const getLocale = (state: ApplicationState) => state.app.locale;

/**
 * Function called for saving the applied filters view to db.
 */
function* handleSaveAppliedFiltersView({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/pageView/view',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for deleting the applied filters view from db.
 */
function* handleDeleteAppliedFiltersView({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/pageView/view',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for fetching the Page Views list from the api.
 */
function* handleFetchPageAppliedFiltersViewListRequestAction({
    payload: sagaPayload,
}: any) {
    const { pageName, callback } = sagaPayload;
    try {
        yield delay(500);
        let pageViewName = '';
        if (pageName === PAGE_NAMES_FOR_VIEW.SUPPORT_PAGE) {
            pageViewName = 'companies';
        } else if (pageName === PAGE_NAMES_FOR_VIEW.ANNOUNCEMENTS_PAGE) {
            pageViewName = 'announcements';
        }
        const PageViews = [
            {
                Id: '88be0b33-88bd-4167-b103-688b60f847d0',
                CreatedUser: {
                    UserId: '00000000-0000-0000-0000-000000000000',
                    GivenName: null,
                    FamilyName: null,
                },
                Name: `All ${pageViewName}`,
                Category: 'Default',
                FilterState: null,
                DashboardState: null,
                IsDefaultView: true,
            },
        ];
        if (PageViews) {
            if (callback) callback(PageViews);
        }
        return null;
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.', err);
        }
        if (callback) callback([]);
    }
}

/**
 * Function called for getting the presigned url for uploading an attachment.
 */
function* handleGetAttachmentPresignedUrl({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/document/attachtoresource',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for deleting an attachment.
 */
function* handleDeleteAttachment({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/document/attachtoresource',
            {
                body: payload,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
            yield put(handleReduxErrorAction(err));
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for handling redux error
 */
function* handleHandleReduxError({ payload: err }: any) {
    yield;
    if (includes(get(err, 'message'), 'Cannot retrieve a new session')) {
        const oAuth = get(config, 'Auth.oauth');
        if (oAuth) {
            Modal.error({
                title: 'Session expired',
                content: 'Click the button to reauthenticate!',
                onOk: () => {
                    const scopeList = get(oAuth, 'scope', []);
                    const scopeString = scopeList.join(' ');
                    const hostedUIPath = `https://${get(
                        oAuth,
                        'domain'
                    )}/login?client_id=${get(
                        oAuth,
                        'client_id'
                    )}&response_type=${get(
                        oAuth,
                        'responseType'
                    )}&scope=${scopeString}&redirect_uri=${get(
                        oAuth,
                        'redirectSignIn'
                    )}`;
                    window.location.href = hostedUIPath;
                },
            });
        }
    }
}

function* watchSaveAppliedFiltersView() {
    yield takeLatest(
        CommonFiltersActionTypes.SAVE_APPLIED_FILTERS_VIEW,
        handleSaveAppliedFiltersView
    );
}

function* watchDeleteAppliedFiltersView() {
    yield takeLatest(
        CommonFiltersActionTypes.DELETE_APPLIED_FILTERS_VIEW,
        handleDeleteAppliedFiltersView
    );
}

function* watchFetchPageAppliedFiltersViewListRequest() {
    yield takeLatest(
        CommonFiltersActionTypes.FETCH_PAGE_APPLIED_FILTERS_VIEW_LIST_REQUEST,
        handleFetchPageAppliedFiltersViewListRequestAction
    );
}

function* watchGetAttachmentPresignedUrl() {
    yield takeLatest(
        CommonFiltersActionTypes.GET_ATTACHMENT_PRESIGNED_URL,
        handleGetAttachmentPresignedUrl
    );
}

function* watchDeleteAttachment() {
    yield takeLatest(
        CommonFiltersActionTypes.DELETE_ATTACHMENT,
        handleDeleteAttachment
    );
}

function* watchHandleReduxError() {
    yield takeLatest(
        CommonFiltersActionTypes.HANDLE_REDUX_ERROR,
        handleHandleReduxError
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* commonSaga() {
    yield all([
        fork(watchSaveAppliedFiltersView),
        fork(watchDeleteAppliedFiltersView),
        fork(watchFetchPageAppliedFiltersViewListRequest),
        fork(watchGetAttachmentPresignedUrl),
        fork(watchDeleteAttachment),
        fork(watchHandleReduxError),
    ]);
}

export default commonSaga;
