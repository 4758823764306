import { get } from 'lodash';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import config from '../../AmplifyConfig';
import { ApplicationState } from '..';
import { DynamicObject } from '../../utils/commonInterfaces';
import { AuthActionTypes } from './types';
import {
    getRegionKeyConfigAction,
    getRegionSettingsConfigAction,
    updateRegionKeyConfigAction,
    updateRegionSettingsConfigAction,
} from './actions';
import { maxAPIRefetchCount, refetchAPIDelay } from '../../config/config';
import { checkShouldRequestRefetch } from '../../utils/commonFunctions';

/**
 * Function used in useSelector for getting the region setting config.
 */
export const getRegionSettingConfig = (state: ApplicationState) =>
    state.auth.regionSettingsConfig;

/**
 * Function used in useSelector for getting the region key config.
 */
export const getRegionKeyConfig = (state: ApplicationState) =>
    state.auth.regionKeyConfig;

/**
 * Function for getting the currentUser
 */
export const getCurrentUser = (state: ApplicationState) =>
    state.auth.currentUser;

let refetchCount = 0;

/**
 * Function called for getting the region key config from the json file.
 */
function* handleGetRegionSettingsConfig() {
    try {
        const res: DynamicObject = yield call(
            fetch,
            `${get(config, 'API.endpoints.0.endpoint')}/region`
        );
        const responseData: DynamicObject = yield res.json();
        const regionSettingConfig = get(responseData, 'Settings');
        refetchCount = 0;
        yield put(updateRegionSettingsConfigAction(regionSettingConfig));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getRegionSettingsConfigAction());
        } else {
            console.log('Error trying to get region settings', err);
        }
    }
}

/**
 * Function called for getting the region key config from the json file.
 */
function* handleGetRegionKeyConfig() {
    try {
        const jsonResponse: DynamicObject = yield call(
            fetch,
            `${window.location.origin}/multi-region-config.json`
        );
        const regionKeyConfig: DynamicObject[] = yield jsonResponse.json();
        refetchCount = 0;
        yield put(updateRegionKeyConfigAction(regionKeyConfig));
    } catch (err) {
        console.log('err: ', err);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getRegionKeyConfigAction());
        } else {
            console.log('Error trying to get region key config', err);
        }
    }
}

function* watchGetRegionSettingsConfig() {
    yield takeLatest(
        AuthActionTypes.GET_REGION_SETTINGS_CONFIG,
        handleGetRegionSettingsConfig
    );
}

function* watchGetRegionKeyConfig() {
    yield takeLatest(
        AuthActionTypes.GET_REGION_KEY_CONFIG,
        handleGetRegionKeyConfig
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* authSaga() {
    yield all([
        fork(watchGetRegionSettingsConfig),
        fork(watchGetRegionKeyConfig),
    ]);
}

export default authSaga;
