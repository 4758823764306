import { Drawer } from 'antd';
import { filter, get } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../less/announcements.less';
import { keyIndexName } from '../../pages/announcements/AnnouncementsManagementPage';
import { ApplicationState } from '../../store';
import { getAnnouncementDataSuccessAction } from '../../store/announcements/actions';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import EditAnnouncementPreview from './EditAnnouncementPreview';

interface IProps {
    visible: boolean;
    announcementId: string | undefined;
    closeDrawer: () => void;
}
const usedDrawerWidth = 530;
const AnnouncementDetailsDrawerComponent: React.FC<IProps> = ({
    visible,
    announcementId,
    closeDrawer,
}: IProps) => {
    const drawerRef = useRef<any>(null);
    const announcementsState = useSelector(
        (app: ApplicationState) => app.announcements
    );
    const dispatch = useDispatch();

    const initializeData = () => {
        const recordData = get(
            filter(announcementsState.data, {
                [keyIndexName]: announcementId,
            }),
            0
        );

        const dataPayload = {
            record: recordData,
        };
        dispatch(getAnnouncementDataSuccessAction(dataPayload));
    };

    useEffect(initializeData, [announcementId]);

    return (
        <Drawer
            className="drawer-with-tabs"
            visible={visible}
            width={usedDrawerWidth}
            onClose={closeDrawer}
            destroyOnClose
            zIndex={1300}
            bodyStyle={{
                width: usedDrawerWidth,
            }}
        >
            {visible && announcementId && (
                <div className="h-100 announcement-view-cont" ref={drawerRef}>
                    <EditAnnouncementPreview
                        formState={announcementsState.activeData.record}
                    />
                </div>
            )}
        </Drawer>
    );
};

export default withDateFormatHandler(AnnouncementDetailsDrawerComponent);
