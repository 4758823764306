// import the library
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faQuestionCircle as farFaQuestionCircle,
    faTimesCircle as farFaTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
// import your icons
import {
    faAddressBook,
    faAddressCard,
    faAmbulance,
    faClipboardList,
    faCloudDownloadAlt,
    faCog,
    faComments,
    faFilter,
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faInfo,
    faList,
    faPlusSquare,
    faQuestionCircle,
    faSignInAlt,
    faSignOutAlt,
    faSortAmountDown,
    faSortAmountUp,
    faSortAmountUpAlt,
    faSortDown,
    faStreetView,
    faSync,
    faTimesCircle,
    faUser,
    faUsers,
    faEllipsisH,
    faBullhorn,
    faEdit,
    faTrash,
    faStickyNote,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faStreetView,
    faAmbulance,
    faPlusSquare,
    faClipboardList,
    faSignInAlt,
    faSignOutAlt,
    faList,
    faAddressCard,
    faQuestionCircle,
    faUsers,
    faSortDown,
    faTimesCircle,
    faSync,
    faComments,
    faAddressBook,
    faFilter,
    faSortAmountDown,
    faSortAmountUp,
    faSortAmountUpAlt,
    faCloudDownloadAlt,
    faUser,
    faInfo,
    faCog,
    faGlobeAfrica,
    faGlobeAmericas,
    faGlobeAsia,
    faGlobeEurope,
    faEllipsisH,
    faBullhorn,
    faEdit,
    faTrash,
    faStickyNote,
    // regular icons go here
    farFaQuestionCircle,
    farFaTimesCircle
    // brand icons go here
);
