import update from 'immutability-helper';
import { concat, isEqual, uniqWith } from 'lodash';
import { Reducer } from 'redux';
import { ANNOUNCEMENTS_PAGE } from '../../config/tableAndPageConstants';
import { announcementsSortByOptions } from '../../constants/announcementsSortAndFilters';
import { AnnouncementsActionTypes, AnnouncementsState } from './types';

// Type-safe initialState!
export const initialState: AnnouncementsState = {
    loading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: ANNOUNCEMENTS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {},
    tableFilter: undefined,
    sortBy: announcementsSortByOptions[0].value, // 2 for Announcement Title
    sortAscending: true,
    regionFilterOptions: [],
    regionFilterOptionsLoading: false,
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
    },
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<AnnouncementsState> = (state = initialState, action) => {
    switch (action.type) {
        case AnnouncementsActionTypes.GET_ANNOUNCEMENTS_REQUEST: {
            return { ...state, loading: true };
        }
        case AnnouncementsActionTypes.GET_ANNOUNCEMENTS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.data, {
                    $set: action.payload.data,
                });
            } else {
                // update(state.data, {
                //     $push: action.payload.data,
                // });
                newDataState = uniqWith(
                    concat(state.data, action.payload.data),
                    isEqual
                );
            }
            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case AnnouncementsActionTypes.GET_ANNOUNCEMENTS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        case AnnouncementsActionTypes.UPDATE_ANNOUNCEMENTS_FILTERS: {
            return { ...state, filters: action.payload };
        }
        case AnnouncementsActionTypes.UPDATE_ANNOUNCEMENTS_SORT_BY: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending,
            };
        }
        case AnnouncementsActionTypes.UPDATE_ANNOUNCEMENTS_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }
        case AnnouncementsActionTypes.CLEAR_ANNOUNCEMENTS_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                tableFilter: initialState.tableFilter,
            };
        }
        case AnnouncementsActionTypes.CLEAR_ANNOUNCEMENTS_STATE_DATA: {
            return {
                ...initialState,
            };
        }

        //Single Record
        case AnnouncementsActionTypes.SET_ANNOUNCEMENT_SELECTED_ID_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                    },
                },
            });

            return newState;
        }

        case AnnouncementsActionTypes.SET_ANNOUNCEMENT_SELECTED_ID_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return newState;
        }

        case AnnouncementsActionTypes.GET_ANNOUNCEMENT_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: true,
                    },
                },
            });

            return newState;
        }

        case AnnouncementsActionTypes.GET_ANNOUNCEMENT_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages: initialState.activeData.errorMessages,
                    },
                },
            });

            return newState;
        }

        case AnnouncementsActionTypes.GET_ANNOUNCEMENT_DATA_ERROR: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return newState;
        }

        case AnnouncementsActionTypes.CLEAR_ANNOUNCEMENT_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as announcementsReducer };
