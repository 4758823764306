/**
 * Component for populating protected button (controlled by user role).
 */

import { Button } from 'antd';
import { includes, isEmpty } from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../store/roles/sagas';

interface IProps {
    buttonProps: {};
    allowedRoles?: string[];
    children: any;
}
const ProtectedButton: React.FC<IProps> = (props: IProps) => {
    const userRole = useSelector(getUserRole);

    return isEmpty(props.allowedRoles) ||
        includes(props.allowedRoles, userRole) ? (
        <Button {...props.buttonProps}>{props.children}</Button>
    ) : null;
};

export default memo(ProtectedButton);
