export const dateFormatDDMMYYYYSlash = 'DD/MM/YYYY';
export const dateFormatDMMMYYYYSpace = 'D MMM YYYY';
export const dateFormatDDMMMYYYYSpace = 'DD MMM YYYY';
export const dateFormatDoMMMYYYYSpace = 'Do MMM YYYY';
export const dateFormatDoMMMYYYYhmmASpace = 'Do MMM YYYY h:mm A';
export const dateFormatDoMMMYYYYHHmmSpace = 'Do MMM YYYY HH:mm';
export const dateFormatYYYYMMDDTHHmmssDash = 'YYYY-MM-DDTHH:mm:ss';
export const dateFormatYYYYMMDDTHHmmssSSSSSSDash = 'YYYY-MM-DDTHH:mm:ss.SSSSSS';
export const dateFormatDDMMMYYYYHHmmSpace = 'DD MMM YYYY HH:mm';
export const dateFormatDDMMMMYYYYhmmASpace = 'DD MMMM YYYY h:mm A';
export const dateFormatDDMMMYYYYhmmASpace = 'DD MMM YYYY h:mm A';
export const dateFormatYYYYMMDDDash = 'YYYY-MM-DD';
export const dateFormatDDMMMYYYYhmmaSpace = 'DD MMM YYYY h:mma';
export const dateFormatTimestamp = 'x';
export const dateFormatFileDownload = 'YYYYMMDDHHmmss';
export const dateFormatYYYYMMDDT00Dash = 'YYYY-MM-DDT00:00:00';
export const dateFormatNowPlaceholder = 'DDMMYYHHmmss';
export const timeFormatHHmm = 'HH:mm';
