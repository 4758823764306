import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal/Modal';
import Select, { SelectValue } from 'antd/lib/select';
import { API } from 'aws-amplify';
import { forEach, get, includes, map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import queries from '../../graphql/queries.graphql';
import { populatePopoverContainer } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
const { Option } = Select;

const FILTER_SEARCH_DEBOUNCE_TIME = 2000; //ms
let unmounted: boolean = false;
interface IProps {
    updateField: (value: any) => void;
    stateValue: string;
    queryName: string;
    filterField: string | undefined;
    queryFilterName: string;
    responseName: string;
    labelField: string;
    updateFiltersFunction: (defaultValue?: any) => void;
}
let timeoutHandle: any = null;

const InputSelectSearchWithButton: React.FC<IProps> = ({
    updateField,
    stateValue,
    queryName,
    filterField,
    queryFilterName,
    responseName,
    labelField,
    updateFiltersFunction,
}: IProps) => {
    const containerRef = useRef(null);
    const [inputState, setInputState] = useState<{
        fetching: boolean;
        data: [];
        value: string | undefined;
    }>({
        fetching: false,
        data: [],
        value: stateValue || undefined,
    });

    /**
     * Common function for updating the input state.
     * @param inputStateObject - must conform to inputState object
     */
    const updateInputState = (inputStateObject: {}) => {
        setInputState({
            ...inputState,
            ...inputStateObject,
        });
    };

    const checkStateValueUpdate = () => {
        if (!stateValue) {
            updateInputState({
                fetching: false,
                data: [],
                value: stateValue || undefined,
            });
        }
    };

    useEffect(checkStateValueUpdate, [stateValue]);

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmounted = false;

        //will unmount
        return () => {
            unmounted = true;
        };
    };

    useEffect(setInitialLoad, []);

    const fetchOptions = (value: string) => {
        // updateField(value);
        if (value.length >= 3) {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            timeoutHandle = setTimeout(() => {
                updateInputState({ value, data: [], fetching: true });

                getDataOptions(value);
            }, FILTER_SEARCH_DEBOUNCE_TIME);
        } else {
            if (timeoutHandle) clearTimeout(timeoutHandle);
            updateInputState({ value, data: [], fetching: false });
        }
    };

    const getDataOptions = (value: string) => {
        if (!queryFilterName) return;
        const queryVariables: DynamicObject = {};

        queryVariables[queryFilterName] = value;

        API.graphql({
            query: queries[queryName],
            variables: queryVariables,
        })
            .then((res: any) => {
                if (unmounted) return;
                const data: DynamicObject[] = [];
                forEach(
                    get(res, `data.${responseName}`),
                    (opt: DynamicObject) => {
                        if (!includes(data, opt)) {
                            data.push(opt);
                        }
                    }
                );

                updateInputState({ value, data, fetching: false });
            })
            .catch(() => {
                if (unmounted) return;
                updateInputState({ value, data: [], fetching: false });
            });
    };

    const handleChange = (value: SelectValue) => {
        updateInputState({
            value: value as string,
            // data: [],
            fetching: false,
        });
    };

    const handleSelect = (value: SelectValue) => {
        updateField(value);
    };

    const onOkClick = () => {
        if (timeoutHandle) clearTimeout(timeoutHandle);

        if (value) {
            updateInputState({
                fetching: false,
                data: [],
                value: value || undefined,
            });
            updateFiltersFunction(value);
        } else {
            Modal.error({
                title: 'Error',
                content: 'Please select a user from the list',
                getContainer: populatePopoverContainer(containerRef),
            });
        }
    };

    const { fetching, data, value } = inputState;
    const getSelectOptions = () => {
        if (!filterField) return null;
        return map(data, (d: DynamicObject) => {
            let label = '';
            forEach(labelField, (lf: string) => {
                if (label !== '') label += ' ';
                label += get(d, lf) || '';
            });
            return (
                <Option key={get(d, filterField)} value={JSON.stringify(d)}>
                    {label}
                </Option>
            );
        });
    };

    return (
        <div className="pop-action-content" ref={containerRef}>
            <div>
                <Select
                    showSearch
                    value={value}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={fetchOptions}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    notFoundContent={null}
                    placeholder="Search"
                    getPopupContainer={populatePopoverContainer(containerRef)}
                    loading={fetching}
                >
                    {getSelectOptions()}
                </Select>
            </div>
            <div className="ok-button-container">
                <Button type="primary" onClick={onOkClick}>
                    Ok
                </Button>
            </div>
        </div>
    );
};

export default InputSelectSearchWithButton;
