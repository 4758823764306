/**
 * Component for the Sales > Announcements page. `/`
 */

import { Col, Modal, Row, Typography } from 'antd';
import {
    difference,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnnouncementDetailsDrawerComponent from '../../components/announcements/AnnouncementDetailsDrawerComponent';
import AnnouncementItemComponent from '../../components/announcements/AnnouncementItemComponent';
import ActionBar from '../../components/common/ActionBar';
import FontAwesome from '../../components/common/FontAwesome';
import VirtualizedList from '../../components/common/VirtualizedList';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../config/config';
import {
    ANNOUNCEMENTS_PAGE,
    PAGE_NAMES_FOR_VIEW,
} from '../../config/tableAndPageConstants';
import {
    announcementsSortByOptions,
    announcementTagOptions,
} from '../../constants/announcementsSortAndFilters';
import '../../less/announcements.less';
import { ApplicationState } from '../../store';
import {
    clearAnnouncementDataAction,
    deleteAnnouncementRequestAction,
    getAnnouncementsRequestAction,
    setSelectedAnnouncementIdRequestAction,
    updateAnnouncementsFiltersAction,
    updateAnnouncementsSortByStateAction,
    updateAnnouncementsTableFilterAction,
} from '../../store/announcements/actions';
import { initialState } from '../../store/announcements/reducer';
import { getSelectedAnnouncementId } from '../../store/announcements/sagas';
import {
    AnnouncementsState,
    GetAnnouncementsRequestPayload,
} from '../../store/announcements/types';
import {
    computeTableScroll,
    emptyPredefinedFilterOnAppliedFilters,
    getUsedSortByOptionsIfCloud,
    handlePageViewSelection,
} from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';

const AnnouncementUpdateComponent = lazy(
    () => import('../../components/announcements/AnnouncementUpdateComponent')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Title } = Typography;

export const keyIndexName = 'ProductAnnouncementId';

interface IProps {
    readonly isUsingCloudImportType: boolean;
}
let resetTableScroll = false;
let isRefetching = false;
let fetchAnnouncementsRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = ANNOUNCEMENTS_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.ANNOUNCEMENTS_PAGE;
let initialTableFilter: undefined | string = undefined;
const AnnouncementsManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
}: IProps) => {
    const dispatch = useDispatch();

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();
    const selectedId = useSelector(getSelectedAnnouncementId);

    const [filterItemList, setFilterItemList] = useState<DynamicObject[]>([]);

    const [announcementsDetailsDrawer, setAnnouncementsDetailsDrawer] =
        useState<{
            visible: boolean;
            announcementId: undefined | string;
        }>({
            visible: false,
            announcementId: undefined,
        });

    const announcementsState: AnnouncementsState = useSelector(
        (state: ApplicationState) => state.announcements
    );

    const [announcementsTableFilter, setAnnouncementsTableFilter] = useState<
        string | undefined
    >(
        isEqual(announcementsState.filters, initialState.filters)
            ? initialState.tableFilter
            : announcementsState.tableFilter
    );

    const [actionBarItemsState, setActionBarItemsState] = useState<{
        editAnnouncementModal: boolean;
        selectedAnnouncementId: string | undefined;
        removeAnnouncementModal: boolean;
    }>({
        editAnnouncementModal: false,
        selectedAnnouncementId: undefined,
        removeAnnouncementModal: false,
    });

    const [tableFilters, setTableFilters] = useState<any>(
        announcementsState.filters
    );

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    /**
     * Function that checks if there are filters applied and saved in redux.
     * Returns a boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(announcementsState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        allSelected: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        allSelected: false,
    });

    const [tableSortState, setTableSortState] = useState<{
        sortBy: string;
        sortAscending: boolean;
    }>({
        sortBy: announcementsState.sortBy,
        sortAscending: announcementsState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(announcementsState, 'pageData.currentPage', 0)
    );

    /**
     * Function to get used sort options
     */
    const getAnnouncementsSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(
            announcementsSortByOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        const usedSortByOptions = getAnnouncementsSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );

        if (!includes(usedSortValues, announcementsState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortState,
                    sortBy: initialState.sortBy,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updateAnnouncementsSortByStateAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [announcementsState.sortBy]);

    /**
     * Function for getting the filter values to be used in FilterBar component.
     */
    const populateAnnouncementsFilterBarFilters = () => {
        const filterBarFilters = [
            {
                filterName: 'Title',
                filterStateName: 'Title',
                filterElement: 'input',
                filterType: 'text',
            },
            {
                filterName: 'Tag',
                filterStateName: 'Tag',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: announcementTagOptions,
            },
        ];

        setFilterItemList(filterBarFilters);
    };

    useEffect(populateAnnouncementsFilterBarFilters, []);

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetAnnouncementsRequestPayload = {
            filters: announcementsState.filters,
            sortBy: tableSortState.sortBy,
            sortAscending: tableSortState.sortAscending,
            pageSize,
            currentPage: currentPage,
        };

        return payload;
    };

    /**
     * Function that calls an action which triggers a saga for fetching the Announcements data.
     * @param currentPage - current page to fetch data from
     * @param pageSize - number of items in page
     */
    const fetchAnnouncements = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;

        dispatch(getAnnouncementsRequestAction(payload));
    };

    /**
     * Function called in preparation for fetching the announcements data.
     * Manages the page to be called.
     */
    const handleFetch = () => {
        if (isUndefined(initialTableFilter) || announcementsState.loading)
            return;
        if (announcementsState.loading) return;

        if (!announcementsState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchAnnouncements(nextPage);
    };

    /**
     * Listener function for calling the fetch announcements function.
     * Can be triggered upon changes on selected user announcement, filter values, sort values, and announcements state filter update.
     */
    const callFetchAnnouncements = () => {
        fetchAnnouncements(0);
    };

    useEffect(callFetchAnnouncements, [
        announcementsState.sortBy,
        announcementsState.sortAscending,
        announcementsState.filters,
    ]);

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = announcementsState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchAnnouncementsRequired) {
                fetchAnnouncements(0);
                fetchAnnouncementsRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [announcementsState.tableFilter]);

    // on Unmount
    useEffect(() => {
        return () => {
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    /**
     * Listener function responsible for showing the state of checkboxes (checked/unchecked) upon data update.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(announcementsState.data, keyIndexName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [announcementsState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function called when announcements table filter (upper-left dropdown next to refresh button) is updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeAnnouncementsTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== announcementsState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setAnnouncementsTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === announcementsState.tableFilter) {
            handleAnnouncementsFilterRefresh();
        } else {
            dispatch(updateAnnouncementsTableFilterAction(filter));
        }
    };

    /**
     * Function called when the refresh button is clicked.
     */
    const handleAnnouncementsFilterRefresh = () => {
        fetchAnnouncementsRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchAnnouncements(0);
    };

    /**
     * Function for populating the dropdown render of the table filter (upper-left).
     * @param menu - menu items component
     */
    const populateAnnouncementsFilterSelectDropdownRender = (menu: any) => (
        <div>{menu}</div>
    );

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (announcementsState.tableFilter !== initialTableFilter) {
            setAnnouncementsTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeAnnouncementsTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called when applying the filters.
     * Set the filters in state and redux.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetAnnouncementsRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    announcementsTableFilter,
                    fromFilterBar,
                    () => {
                        changeAnnouncementsTableFilter('');
                    }
                );
            }
        }

        const appliedFilters = filters || initialState.filters;

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateAnnouncementsFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys();
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param showConditionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function for updating the `actionBarItemsState` state.
     * @param itemStateObject - must conform to `actionBarItemsState`
     */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState({
            ...actionBarItemsState,
            ...itemStateObject,
        });
    };

    /**
     * Function called to close the announcements details drawer.
     * Clears the data saved during drawer close.
     */
    const closeAnnouncementDetailsDrawer = () => {
        setAnnouncementsDetailsDrawer({
            visible: false,
            announcementId: undefined,
        });
        dispatch(clearAnnouncementDataAction());
    };

    /**
     * Function called to close the update announcement modal.
     */
    const closeAnnouncementModal = () => {
        updateActionBarItemsState({
            editAnnouncementModal: false,
            selectedAnnouncementId: undefined,
        });
    };

    /**
     * Function to update the `tableSortState` state.
     * @param tableSortStateObject - must conform to `tableSortState`
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when clicking on checkbox.
     * @param record - record for the row where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     */
    const onRowClick = (record: DynamicObject) => {
        const announcementId = get(record, keyIndexName);

        dispatch(
            setSelectedAnnouncementIdRequestAction(announcementId, () => {
                setAnnouncementsDetailsDrawer({
                    visible: true,
                    announcementId,
                });
            })
        );
    };

    /**
     * Function for populating the table loading text.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } announcements`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function called when the row is selected (left side checkbox is ticked).
     * @param record - data for the selected row
     * @param selected - boolean indicator if the row is selected/unselected
     * @param selectedRows - selected row keys
     * @param nativeEvent - event fired when the row is selected
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });
    };

    /**
     * Function for resetting the table scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all the selected/unselected row keys.
     */
    const resetAllSelectedRowKeys = () => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        updateShowConditionsObject({
            allSelected: false,
        });

        updateActionBarItemsState({
            raiseQueryButtonDisabled: true,
        });
    };

    /**
     * Function for closing the filter bar section.
     * Resets the applied filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function called when new announcement button is clicked.
     */
    const onNewAnnouncementClick = () => {
        updateActionBarItemsState({
            editAnnouncementModal: true,
            selectedAnnouncementId: undefined,
        });
    };

    /**
     * Function called when edit announcement button is clicked.
     */
    const onEditAnnouncementClick = () => {
        const selectedId = get(tableRowSelection.selectedRowKeys, 0);
        updateActionBarItemsState({
            editAnnouncementModal: true,
            selectedAnnouncementId: selectedId,
        });
    };

    /**
     * Function called when delete announcement button is clicked.
     */
    const onDeleteAnnouncementClick = () => {
        const selectedId = get(tableRowSelection.selectedRowKeys, 0);
        Modal.confirm({
            className: 'modal-swapped-buttons',
            title: 'Do you want to continue?',
            content: (
                <div>
                    When you click the <b>{confirmModalOkText}</b> button, the
                    announcement will be removed.
                </div>
            ),
            onOk: () => deleteAnnouncement(selectedId),
            okText: confirmModalOkText,
            cancelText: confirmModalCancelText,
        });
    };

    /**
     * Function that calls the action to delete an announcement
     */
    const deleteAnnouncement = (announcementId: string) => {
        Modal.destroyAll();
        updateActionBarItemsState({
            removeAnnouncementModal: true,
        });
        dispatch(
            deleteAnnouncementRequestAction(
                {
                    ProductAnnouncementId: announcementId,
                },
                ({ IsSuccess, Messages }: ResponseModalObject) =>
                    deleteAnnouncementResponseModal(IsSuccess, Messages)
            )
        );
    };

    /**
     * Function for populating the response modal after deleting the view action API call has finished.
     * @param IsSuccess
     * @param Messages
     * @param defaultViewValue - default value of view dropdown after successfully deleting
     */
    const deleteAnnouncementResponseModal = (
        IsSuccess: boolean,
        Messages: string[] | undefined
    ) => {
        updateActionBarItemsState({
            removeAnnouncementModal: false,
        });

        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Announcement deleted successfully!',
                onOk: handleAnnouncementsFilterRefresh,
            });
        } else {
            let errorMessageContent: string | JSX.Element[] =
                'Failed to delete the announcement!';
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
            });
        }
    };

    /**
     * Function called when an announcement is successfully saved.
     */
    const handleAfterAnnouncementSave = () => {
        closeAnnouncementModal();
        refetchListAndResetScroll();
    };

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>Announcements</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    // pageName={pageName}
                                    loading={announcementsState.loading}
                                    applyFilters={applyFilters}
                                    filters={filterItemList}
                                    filterValues={announcementsState.filters}
                                    colDivision={6}
                                    closeAllPopovers={
                                        announcementsDetailsDrawer.visible
                                    }
                                    closeFilterBar={closeFilterBar}
                                    appliedView={announcementsTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    // customFieldsFilters={customFieldsFilterList}
                                />
                            </Suspense>
                        </div>
                    )}
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={announcementsState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'announcements-filter',
                                actionType: 'select-with-button',
                                selectValue: announcementsTableFilter,
                                selectDropdownRender:
                                    populateAnnouncementsFilterSelectDropdownRender,
                                onSelectChange: changeAnnouncementsTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>Refresh</span>
                                    </>
                                ),
                                buttonDisabled: announcementsState.loading,
                                onButtonClick: handleAnnouncementsFilterRefresh,
                                defaultValue: 'All',
                            },
                            {
                                actionKey: 'announcement-edit',
                                actionType: 'protected-button',
                                buttonDisabled:
                                    isEmpty(
                                        tableRowSelection.selectedRowKeys
                                    ) ||
                                    tableRowSelection.selectedRowKeys.length >
                                        1,
                                onButtonClick: onEditAnnouncementClick,
                                buttonContent: (
                                    <>
                                        <FontAwesome icon={['fas', 'edit']} />
                                        <span>Edit announcement</span>
                                    </>
                                ),
                            },
                            {
                                actionKey: 'announcement-delete',
                                actionType: 'protected-button',
                                buttonDisabled:
                                    isEmpty(
                                        tableRowSelection.selectedRowKeys
                                    ) ||
                                    tableRowSelection.selectedRowKeys.length >
                                        1,
                                onButtonClick: onDeleteAnnouncementClick,
                                buttonContent: (
                                    <>
                                        <FontAwesome icon={['fas', 'trash']} />
                                        <span>Delete announcement</span>
                                    </>
                                ),
                            },
                            {
                                actionKey: 'announcement-new',
                                actionType: 'protected-button',
                                buttonDisabled:
                                    actionBarItemsState.editAnnouncementModal,
                                onButtonClick: onNewAnnouncementClick,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'plus-square']}
                                        />
                                        <span>New announcement</span>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={announcementsState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                ANNOUNCEMENTS_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={announcementsState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(announcementsState.errorMessages)
                                    ? get(announcementsState, 'errorMessages.0')
                                    : 'No announcements found'
                            }
                            hasNextPage={
                                announcementsState.pageData.hasNextPage
                            }
                            itemComponent={AnnouncementItemComponent}
                            itemHeight={ANNOUNCEMENTS_PAGE.rowHeight}
                            selectedId={selectedId}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <AnnouncementDetailsDrawerComponent
                            {...announcementsDetailsDrawer}
                            closeDrawer={closeAnnouncementDetailsDrawer}
                        />
                    </Suspense>
                    <Suspense fallback={null}>
                        <AnnouncementUpdateComponent
                            visible={actionBarItemsState.editAnnouncementModal}
                            selectedId={
                                actionBarItemsState.selectedAnnouncementId
                            }
                            closeModal={closeAnnouncementModal}
                            handleAfterSave={handleAfterAnnouncementSave}
                        />
                    </Suspense>
                    {actionBarItemsState.removeAnnouncementModal && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle="Deleting announcement"
                                modalVisible={
                                    actionBarItemsState.removeAnnouncementModal
                                }
                                displayMessage="Please wait while deleting the announcement . . ."
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default AnnouncementsManagementPage;
