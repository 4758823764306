/**
 * File responsible for the content when clicking `Gain Access` in management pages.
 */

import { Button, Col, Form, Modal, Row, Select } from 'antd';
import { filter, get, isEmpty, map } from 'lodash';
import React, { lazy, Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Environments } from '../../AmplifyConfig';
import {
    SYSTEM_ENDPOINT_DEV,
    SYSTEM_ENDPOINT_NONPROD,
    SYSTEM_ENDPOINT_PROD,
    SYSTEM_ENDPOINT_TEST,
    SYSTEM_ENDPOINT_UAT,
} from '../../config/config';
import { getAllRoles } from '../../store/roles/sagas';
import { companyGainAccessRequestAction } from '../../store/support/actions';
import {
    getCurrentBuildEnvironment,
    getPopoverContainer,
    openURLNewTab,
} from '../../utils/commonFunctions';

const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly filterPayload?: {};
}

const formItemNames = {
    ACCESS_LEVEL: 'Role',
};

const SupportGainAccess: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
    filterPayload,
}: IProps) => {
    const dispatch = useDispatch();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { getFieldDecorator, validateFields } = form;

    const allRoles = useSelector(getAllRoles);

    /**
     * Function called when `Ok` button is clicked after successful access request.
     */
    const handleClosePanel = (refetchList: boolean) => {
        if (closePanel) closePanel(refetchList);
    };

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                dispatch(
                    companyGainAccessRequestAction({
                        ...filterPayload,
                        Role: get(values, formItemNames.ACCESS_LEVEL),
                        callback: gainAccessResponseModal,
                    })
                );
            }
        });
    };

    /**
     * Function responsible for showing the response modal after requesting access..
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const gainAccessResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: 'Access requested successfully!',
                onOk: () => handleClosePanel(false),
                getContainer: () => getPopoverContainer(containerRef),
            });
        } else {
            let errorMessageContent: any = `Failed to request access!`;
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }

            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    /**
     * Function for getting all the role options.
     */
    const getRoleOptions = () => {
        return map(allRoles, (role: any) => (
            <Option key={role.RoleId}>{role.Name}</Option>
        ));
    };

    /**
     * Function for getting the initial value for access level (which is the Administrator Role Id).
     */
    const getRoleInitialValue = () => {
        return get(filter(allRoles, ['Name', 'Administrator']), '0.RoleId');
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form
                className="support-inline-form"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
            >
                <Row>
                    <Col>
                        Click to temporarily gain access to this company
                        account.
                    </Col>
                </Row>
                <Row className="mt-10 mb-10">
                    <Col span={24}>
                        <FormItem label="Access level">
                            {getFieldDecorator(formItemNames.ACCESS_LEVEL, {
                                initialValue: getRoleInitialValue(),
                            })(<Select>{getRoleOptions()}</Select>)}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };

    /**
     * Function that opens the production system based on current build environment.
     */
    const openProductionSystem = () => {
        const env = getCurrentBuildEnvironment();
        let systemPath;
        if (env === Environments.DEV) {
            systemPath = SYSTEM_ENDPOINT_DEV;
        } else if (env === Environments.PROD) {
            systemPath = SYSTEM_ENDPOINT_PROD;
        } else if (env === Environments.TEST) {
            systemPath = SYSTEM_ENDPOINT_TEST;
        } else if (env === Environments.UAT) {
            systemPath = SYSTEM_ENDPOINT_UAT;
        } else if (env === Environments.NONPROD) {
            systemPath = SYSTEM_ENDPOINT_NONPROD;
        }

        openURLNewTab(`https://${systemPath}`);
    };

    if (!visible) {
        return null;
    }

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <Row>
                    <Col className="ta-right" span={24}>
                        <div>
                            <Button type="primary" onClick={handleSubmitForm}>
                                Access account
                            </Button>
                        </div>
                        <div className="mt-10">
                            <Button
                                type="primary"
                                className="btn-with-html"
                                onClick={openProductionSystem}
                            >
                                <div>Click to open</div>
                                <div>Production system</div>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle="Requesting access"
                        modalVisible={submitLoading}
                        displayMessage="Please wait while requesting access for the company . . ."
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const SupportGainAccessForm = Form.create({
    name: 'support-gain-access-panel-form',
})(SupportGainAccess);

export default withRouter(SupportGainAccessForm);
