import { action } from 'typesafe-actions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import {
    Announcement,
    AnnouncementsActionTypes,
    GetAnnouncementsRequestPayload,
} from './types';

/**
 * Actions for getting the announcement list in Announcements page.
 */
export const getAnnouncementsRequestAction = (
    payload: GetAnnouncementsRequestPayload
) => action(AnnouncementsActionTypes.GET_ANNOUNCEMENTS_REQUEST, payload);
export const getAnnouncementsSuccessAction = (payload: {
    data: Announcement[];
    pageData: PageData;
}) => action(AnnouncementsActionTypes.GET_ANNOUNCEMENTS_SUCCESS, payload);
export const getAnnouncementsErrorAction = (payload: string[]) =>
    action(AnnouncementsActionTypes.GET_ANNOUNCEMENTS_ERROR, payload);

export const updateAnnouncementsFiltersAction = (
    filters: GetAnnouncementsRequestPayload['filters']
) => action(AnnouncementsActionTypes.UPDATE_ANNOUNCEMENTS_FILTERS, filters);
export const updateAnnouncementsSortByStateAction = (tableSortState: {
    sortBy: GetAnnouncementsRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        AnnouncementsActionTypes.UPDATE_ANNOUNCEMENTS_SORT_BY,
        tableSortState
    );

export const updateAnnouncementsTableFilterAction = (tableFilter: string) =>
    action(
        AnnouncementsActionTypes.UPDATE_ANNOUNCEMENTS_TABLE_FILTER_STATE,
        tableFilter
    );

// Single Record
export const setSelectedAnnouncementIdRequestAction = (
    announcementId: string | null,
    callback: () => void
) =>
    action(AnnouncementsActionTypes.SET_ANNOUNCEMENT_SELECTED_ID_REQUEST, {
        announcementId,
        callback,
    });
export const setSelectedAnnouncementIdSuccessAction = (
    announcementId: string | null
) =>
    action(
        AnnouncementsActionTypes.SET_ANNOUNCEMENT_SELECTED_ID_SUCCESS,
        announcementId
    );

/**
 * Actions for getting Announcements page - announcement data.
 */
export const getAnnouncementDataRequestAction = (announcementId: string) =>
    action(AnnouncementsActionTypes.GET_ANNOUNCEMENT_DATA_REQUEST, {
        announcementId,
    });
export const getAnnouncementDataSuccessAction = (payload: { record: {} }) =>
    action(AnnouncementsActionTypes.GET_ANNOUNCEMENT_DATA_SUCCESS, payload);
export const getAnnouncementDataErrorAction = (payload: string[]) =>
    action(AnnouncementsActionTypes.GET_ANNOUNCEMENT_DATA_ERROR, payload);

export const clearAnnouncementDataAction = () =>
    action(AnnouncementsActionTypes.CLEAR_ANNOUNCEMENT_DATA_SUCCESS);

/**
 * Action called for triggering the saga for saving an announcement.
 * @param payload
 * @param callback - function called after saving - useful for refetching the list
 */
export const saveAnnouncementRequestAction = (
    payload: DynamicObject,
    callback?: (response: ResponseModalObject) => void
) =>
    action(AnnouncementsActionTypes.SAVE_ANNOUNCEMENT_REQUEST, {
        payload,
        callback,
    });

/**
 * Action called for triggering the saga for deleting the announcement.
 * @param payload
 * @param callback - function called after deleting - useful for refetching the list
 */
export const deleteAnnouncementRequestAction = (
    payload: { ProductAnnouncementId: string },
    callback?: (response: ResponseModalObject) => void
) =>
    action(AnnouncementsActionTypes.DELETE_ANNOUNCEMENT_REQUEST, {
        payload,
        callback,
    });
